












import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Prop} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";

@Component({components:{}})
export default class CardShareBar extends mixins(ComponentBasic){
    @Prop({default:''})
    type!: string

    @Prop({default:'primary'})
    color!: string
}
