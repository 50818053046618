import Vue from 'vue'
import Router from 'vue-router'
import PageHome from '@/page/PageHome.vue'
import PageHoliday from '@/page/PageHoliday.vue'
import PageMarket from '@/page/PageMarket.vue'
import PageContent from '@/page/PageContent.vue'
import PageWeather from '@/page/PageWeather.vue'
import PageArchive from '@/page/PageArchive.vue'
import PageEvent from '@/page/PageEvent.vue'
import PageEvents from '@/page/PageEvents.vue'
import PageStats from '@/page/PageStats.vue'
import PageNameDays from '@/page/PageNameDays.vue'

const NavigationAdmin = () => import('@/admin/NavigationAdmin.vue');
const PageAdminEditor = () => import('@/admin/PageAdminContent.vue');
const PageAdminEvents = () => import('@/admin/PageAdminEvents.vue');
const PageAdminLogin = () => import('@/admin/PageAdminLogin.vue');
const PageAdminSettings = () => import('@/admin/PageAdminSettings.vue');

//import NavigationAdmin from '@/admin/NavigationAdmin.vue'
//import PageAdminEditor from '@/admin/PageAdminContent.vue'
//import PageAdminEvents from '@/admin/PageAdminEvents.vue'
//import PageAdminLogin from '@/admin/PageAdminLogin.vue'
//import PageAdminSettings from '@/admin/PageAdminSettings.vue'

import {TypePath} from "@/utilities/UtilitiesApp";
import NavigationPublic from '@/component/NavigationPublic.vue';
import ComponentFooter from '@/component/ComponentFooter.vue';
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
Vue.use(Router);

const LN = "[route.js]";
let AppRouter = new Router({
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: TypePath.Home.path, alias: '',
      components: {default: PageHome, navigation: NavigationPublic, footer: ComponentFooter}
    },
    {
      path: TypePath.Holidays.path,
      components: {default: PageHoliday, navigation: NavigationPublic, footer: ComponentFooter}
    },
    {
      path: TypePath.Weather.path,
      components: {default: PageWeather, navigation: NavigationPublic, footer: ComponentFooter}
    },
    {
      path: TypePath.Event.path + '/:id?',
      components: {default: PageEvent, navigation: NavigationPublic, footer: ComponentFooter},
    },
    {
      path: TypePath.Events.path + '/:dateText?/:plusDays?/:search?',
      components: {default: PageEvents, navigation: NavigationPublic, footer: ComponentFooter},
    },
    {
      path: TypePath.Stats.path,
      components: {default: PageStats, navigation: NavigationPublic, footer: ComponentFooter},
    },
    {
      path: TypePath.Market.path,
      components: {default: PageMarket, navigation: NavigationPublic, footer: ComponentFooter}
    },
    {
      path: TypePath.Content.path + '/:type?',
      components: {default: PageContent, navigation: NavigationPublic, footer: ComponentFooter},
    },
    {
      path: TypePath.Archive.path + '/:dateFromText?/:dateToText?/:search?',
      components: {default: PageArchive, navigation: NavigationPublic, footer: ComponentFooter},
    },
    {
      path: TypePath.NameDays.path,
      components: {default: PageNameDays, navigation: NavigationPublic, footer: ComponentFooter},
    },
    /*Admin Section*/
    {
      path: TypePath.AdminEditEvents.path,
      components: {default: PageAdminEvents, navigation: NavigationAdmin/*, footer: ComponentFooter*/},
      meta: {requiresAuth: true}
    },
    {
      path: TypePath.AdminEditContent.path,
      components: {default: PageAdminEditor, navigation: NavigationAdmin/*, footer: ComponentFooter*/},
      meta: {requiresAuth: true}
    },
    {
      path: TypePath.AdminEditSettings.path,
      components: {default: PageAdminSettings, navigation: NavigationAdmin/*, footer: ComponentFooter*/},
      meta: {requiresAuth: true}
    },
    {
      path: TypePath.AdminLogin.path,
      components: {default: PageAdminLogin, navigation: NavigationAdmin/*, footer: ComponentFooter*/},
      meta: {requiresAuth: false}
    }

  ]
});


AppRouter.beforeEach(
  //https://router.vuejs.org/guide/advanced/navigation-guards.html
  //to,from: Route next(callback)
  (to, from, next) => {
    UtilitiesBasic.Log(LN , "To [" + (to ? to.path : "n/a") + "] From [" + (from ? from.path : "n/a") + "]");
    let isUserSet = false;
    try {
      if (AppRouter.app.$store) {
        isUserSet = AppRouter.app.$store.getters.getUser != null;
        if (isUserSet)
          UtilitiesBasic.Log(LN ,"Logged ok, user is set in state");
      } else {
        UtilitiesBasic.Log(LN ,"Storage Not defined Yet unable to check logged user");
      }
    } catch (error) {
      isUserSet = false;
      console.log(error);
      //UtilitiesBasic.Log(error);
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !isUserSet) {
      UtilitiesBasic.Log(LN, "Auth required, user not set, fwd to login");
      next(TypePath.AdminLogin.path);
    } else {
      next();
    }
  });

export default AppRouter;
