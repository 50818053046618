import Vue from 'vue'
import Vuex from 'vuex'
import {PayloadStructure} from "@/model/basic/ModelBasic";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings: PayloadStructure.loading("Παρακαλώ περιμένετε"),
    summary: PayloadStructure.loading("Παρακαλώ περιμένετε"),
    news: PayloadStructure.loading("Παρακαλώ περιμένετε"),
    content: null,
    user: null /*UserStructure*/
  },
  getters: {
    getUser: state => state.user,
    getSettings: state => state.settings.hasData() ? state.settings.getPayload() : null,
    getSettingsPayload: state => state.settings,
    getSummaryPayload: state => state.summary,
    getNewsPayload: state => state.news,
    getContentMap: state => state.content,
    getContent: (state) => (id:string) => {
      //@ts-ignore
      return state.content != null ? state.content.get(id) : null
    },
  },
  mutations: {
    setUser: (state, payload) => state.user = payload,
    setSettings: (state, payload) => {
      state.settings = payload;
      if (payload != null && payload.hasData())
        state.content = payload.getPayload().getContentMap();
      else
        state.content = null;
    },
    setSummary: (state, payload) => {
      state.summary = payload;
    },
    setNews: (state, payload) => {
      state.news = payload;
    }
  }
})
