<template>

        <!-- <payload-message-component :payload="getSummaryPayload"
                                    v-if="getSummaryPayload.getType() == TypeMessage.Loading"
                                    style="width:100%;height:100%;vertical-align:middle;padding:30px;"></payload-message-component>-->

        <v-carousel v-if="summaryPayload.getType() == TypeMessage.Info && strikeEventFuture.length>0" height="200"
                    carousel="true" interval="5000"
                    style="background:white" light>
            <v-carousel-item v-for="(event) in strikeEventFuture" :key="event.id"
                             @click="GoToRouter(Util.UtilitiesApp.toURLEvent(event, false))">
                <div style="width:100%;height:100%;color:black;cursor:pointer">
                    <div style="margin:auto;width:70%;height:100%">
                        <table>
                            <tr>
                                <td style="padding-right:30px"><img :src="Util.UtilitiesApp.toImageSubCategoryFromEvent(event)"
                                                                    style="width:150px"/></td>
                                <td>
                                    <h1>{{event.title}}</h1>
                                    <h2 style="color:gray">
                                        <template  v-if="event.getDaysCount()>1">
                                        <span>Από {{Util.UtilitiesApp.toEventDatePretty(event,false,true,false)}}</span>
                                        </template>
                                        <template v-else>
                                            <span>{{Util.UtilitiesApp.toEventDatePretty(event,false,false,false)}}</span>
                                        </template>
                                    </h2>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </v-carousel-item>
        </v-carousel>
</template>
<script>
    import {ComponentBasic} from "@/utilities/UtilitiesVue";

    export default {
        mixins: [ComponentBasic],
        props: {
            countNamedays: {
                default: '2',
                type: String
            }
        },
        data() {
            return {
                LN: "[ListCarousel]::"
            }
        },
        computed: {
            strikeEventFuture: function () {
                return this.summary.getStrikesAfter();
            }
        },
        methods: {
            getNameDay(index) {
                return this.summary && this.summary.getNameDay().length > index ? this.summary.getNameDay()[index] : null;
            }

        }
    }

</script>
