













import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";

@Component({components: {}})
export default class PageHome extends mixins(ComponentBasic) {
    LN: string = "[PageHome]::";

    created() {
        this.Log("Created..Page Home");
    }
}
