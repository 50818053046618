
































import {Vue, Component, Prop} from "vue-property-decorator";
import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {mixins} from "vue-class-component";
import {UtilDate} from "@/utilities/UtilitiesApp";

@Component
export default class ListTile extends mixins(ComponentBasic) {
    LN = "[ListTile]::";

    @Prop({default: false})
    divider!: boolean;

    @Prop({default: null})
    img!: string | null;

    /*url of image*/
    @Prop({default: null})
    icon!: string | null;

    /*icon native path*/
    @Prop({default: null})
    title!: string | null;

    @Prop({default: null})
    titleSub!: string | null;

    @Prop({default: null})
    urlSub!: string | null;

    @Prop({default: null})
    urlTitle!: string | null;

    @Prop({default: null})
    height!: string | null;

    @Prop({default: '48px'})
    imgSize!: string;

    @Prop({default: null})
    countdown!: number;

    countdownNow: number = -1;
    countdownInterval: number | null = null;

    created() {
        if (this.countdown != null) {
            this.countdownNow = Math.trunc((new Date()).getTime() / 1000);
            this.Log("Set interval for " + this.title);
            this.countdownInterval = window.setInterval(() =>this.countdownNow = Math.trunc((new Date()).getTime() / 1000),1000);
        }
    }

    destroyed() {
        if (this.countdownInterval) {
            this.Log("Closing interval for " + this.title);
            window.clearInterval(this.countdownInterval);
        }
    }

    goTo(url: string) {
        this.$router.push({path: url});
    }

    get titleCountDown(): string {
        let result = "";
        let days = this.daysNumber;
        if (days == 1)
            result = "μία ημέρα και ";
        else if (days > 1)
            result = days + " ημέρες και ";

        result += this.hours + ":" + this.minutes + ":" + this.seconds;
        return result
    }

    get seconds(): string {
        if (this.countdown)
            return UtilDate.toStringTwoNumber((this.countdown - this.countdownNow) % 60);
        return "";
    }

    get minutes(): string {
        if (this.countdown)
            return UtilDate.toStringTwoNumber(Math.trunc((this.countdown - this.countdownNow) / 60) % 60);
        return "";
    }

    get hours(): string {
        if (this.countdown)
            return UtilDate.toStringTwoNumber(Math.trunc((this.countdown - this.countdownNow) / 60 / 60) % 24);
        return "";

    }

    get daysNumber(): number {
        if (this.countdown)
            return Math.trunc((this.countdown - this.countdownNow) / 60 / 60 / 24);
        return 0;
    }

    get days(): string {
        if (this.countdown)
            return UtilDate.toStringTwoNumber(Math.trunc((this.countdown - this.countdownNow) / 60 / 60 / 24));
        return "";
    }
}

