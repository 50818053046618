




















































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Language, UtilitiesApp} from "@/utilities/UtilitiesApp";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {EventStructure} from "@/model/ModelApp";
import {RestClientApergia} from "@/utilities/RestClientApergia";

@Component({})
export default class ListNews extends Mixins(ComponentBasic) {
    LN: string = "[ListNews]::";
    listFiltered: EventStructure[] = [];
    payload: PayloadStructure<EventStructure[]> = PayloadStructure.loading(Language.pleaseWait);
    fontSize: string = '14px';

    @Prop({default: new Date().getFullYear()})
    year!: number;

    @Prop({default: true})
    selectable!: boolean;

    @Prop({default: null})
    listEvents!: [] | null;

    @Prop({default: null})
    height!: string | null;

    @Prop({default: null})
    day!: string | null;

    @Prop({default: '40px'})
    imgSize!: string;

    @Prop({default: '450px'})
    heightDiv!: string;

    @Prop({default: false})
    showToolbar!: boolean;

    @Prop({default: false})
    showDate!: boolean;

    created() {
        this.loadData();
    }

    toImage(event: EventStructure) {
        let hostname = UtilitiesApp.getHostnameFromURL(event.url || '').replace('.', '_').replace('.', '_').replace('.', '_').replace("www","");
        return '/extra/img/news.64/' + hostname + '.png';
    }

    goTo(url: string) {
        this.$router.push({path: url});
    }

    @Watch('GetNewsPayload')
    onGetNewsPayload() {
        this.Log("NewsPayload Updated");
        this.loadData();
    }

    async loadData() {
        this.Log("Init");
        try {
            this.payload = PayloadStructure.loading(Language.pleaseWait);
            this.payload = await RestClientApergia.getTrendNews();
            this.listFiltered = this.payload.getPayload() || [];
            //@ts-ignore
            this.listFiltered.sort((a, b) => b.dateCreatedMs > a.dateCreatedMs ? 1 : -1)
        } catch (error) {
            this.payload = PayloadStructure.error("Failed");
        }
    }
}

