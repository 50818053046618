





















































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {EventStructure, EventsWrapperStructure} from "@/model/ModelApp";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {UtilitiesApp, UtilDate, Language} from "@/utilities/UtilitiesApp";
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

@Component({components: {}})
export default class PageEvents extends mixins(ComponentBasic) {
    LN: string = "[PageEvent]::";
    eventId: string | null = null;
    payload: PayloadStructure<EventsWrapperStructure> = PayloadStructure.loading(Language.pleaseWait);
    listEvents: EventStructure[] | null = null;
    dateFrom: Date | null = null;
    dateTo: Date | null = null;
    numOfDays: number = 0;
    color: string = 'white';

    created() {
        this.calcContent();
    }

    get hasCommonSideBar() {
        return false;/*!UtilitiesBasic.isMobile()*/
        ;
    }

    calcContent() {
        //Expecting dateText to 2020.06.12
        this.Log("Parsing input date from [" + this.$route.params.dateText + "]");
        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.dateFrom = UtilitiesApp.toURLDateFromString(this.$route.params.dateText);
        this.Log("Parsed DateFrom [" + this.dateFrom + "]");
        if ((!(this.dateFrom instanceof Date))) {
            this.Log("[Invalid Date From, get current date]");
            this.dateFrom = new Date();
            this.dateFrom.setHours(0, 0, 0, 0);
        }
        //@ts-ignore
        let plusDays = parseInt(this.$route.params.plusDays || 14);
        if (plusDays <= 0)
            plusDays = 14;

        this.dateTo = new Date(this.dateFrom.getTime());
        this.dateTo.setDate(this.dateTo.getDate() + plusDays);
        this.numOfDays = plusDays;
        this.Log("Period Request [" + UtilDate.toStringDatePretty(this.dateFrom) + "-" + UtilDate.toStringDatePretty(this.dateTo) + "] (" + plusDays + ")");

        this.GetEventSearchPromise(this.dateFrom, this.dateTo, null, null, false)
            .then((event) => {
                this.payload = PayloadStructure.info(event);
                this.listEvents = event.listEvents;
            }).catch((error) => {
                this.Log(error);
                this.payload = PayloadStructure.error(Language.error)
            }
        );

    }

    eventsOfDay(date: Date) {
        //@ts-ignore
        let list = this.listEvents.filter(event => (date.getTime() >= event.dateFromMs && date.getTime() < event.dateToMs));
        if (list.length == 0) {
            list.push(EventStructure.event(-1, "Καμία Απεργία", "sun"))
        }
        return list;
    }

    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }

}
