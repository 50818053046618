import axios, {AxiosError, AxiosPromise, AxiosResponse} from 'axios';
import {UtilitiesApp} from '@/utilities/UtilitiesApp';
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {EventStructure, SettingsStructure, SummaryStructure} from "@/model/ModelApp";

const LN = '[RestClient]::';
//http://api.apergia.gr/api/v1
const remote_production = "http://api.apergia.gr/api/v1/";
const remote_relative = '/api/v1/';
const remote_local = 'http://localhost:8181/api/v1/';
const prefix =  remote_relative ;

export class RestClientApergia {

    static async getTrendNews(callbackOk: ((n: AxiosResponse) => void) | null = null, callbackErr: ((n: AxiosError) => void) | null = null): Promise<PayloadStructure<EventStructure[]>> {
        return this.handlePromise('events/news', null, callbackOk, callbackErr)
            .then(value => PayloadStructure.info(SummaryStructure.toEventArray(value.data)))
    }

    static async getTrendYoutube(callbackOk: ((n: AxiosResponse) => void) | null = null, callbackErr: ((n: AxiosError) => void) | null = null): Promise<PayloadStructure<Map<string, EventStructure[]>>> {
        return this.handlePromise('events/youtube', null, callbackOk, callbackErr)
            .then(value => {
                let map: Map<string, EventStructure[]> = new Map();
                for (let key in value.data) {
                    map.set(key, SummaryStructure.toEventArray(value.data[key], false));
                }
                //@ts-ignore
                return PayloadStructure.info(map)
            });
    }

    static async getConvid19(callbackOk: ((n: AxiosResponse) => void) | null = null, callbackErr: ((n: AxiosError) => void) | null = null): Promise<PayloadStructure<any>> {
        return this.handlePromise('other/convid19', null, callbackOk, callbackErr)
            .then(value => {
                //@ts-ignore
                return PayloadStructure.info(value.data)
            });
    }

    //<editor-fold desc="Methods Config">
    static async getConfigAll(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null): Promise<PayloadStructure<SettingsStructure>> {
        return this.handlePromise('events/config-all', null, callbackOk, callbackErr)
            .then(value => {
                    return PayloadStructure.info(SettingsStructure.createSettingsFromJson(value.data))
                }
            );
    }

    static async getSummary(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null): Promise<AxiosResponse> {
        return this.handlePromise('events/summary', null, callbackOk, callbackErr);
    }

    static async getEvent(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, id: string): Promise<AxiosResponse> {
        return this.handlePromise('events/event/' + id, null, callbackOk, callbackErr);
    }

    static async getCache(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, location: string): Promise<AxiosResponse> {
        return this.handlePromise('cache/url/' + location, null, callbackOk, callbackErr);
    }

    static async getEventSearch(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, dateFrom: Date | null, dateTo: Date | null, category: string | null = null, searchText: string | null = null, isArchive: boolean | null = null): Promise<AxiosResponse> {
        return this.handlePromise('events/eventSearch', {
            params: {
                dateFrom: dateFrom == null ? null : UtilitiesApp.toURLStringFromDate(dateFrom),
                dateTo: dateTo == null ? null : UtilitiesApp.toURLStringFromDate(dateTo),
                category: category,
                text: searchText,
                isArchive: isArchive
            }
        }, callbackOk, callbackErr);
    }

    static async getEventStats(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, dateFrom: Date | null, dateTo: Date | null, categories: string[] | null): Promise<AxiosResponse> {
        return this.handlePromise('events/eventStats', {
            params: {
                dateFrom: dateFrom == null ? null : UtilitiesApp.toURLStringFromDate(dateFrom),
                dateTo: dateTo == null ? null : UtilitiesApp.toURLStringFromDate(dateTo),
                categories: categories
            }
        }, callbackOk, callbackErr);
    }

    /**
     * Used in Namedays ONLY
     */
    static async getEventOfCategory(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, dateFrom: Date | null, dateTo: Date | null, category: string | null): Promise<AxiosResponse> {
        return this.handlePromise('events/eventOfCategory', {
            params: {
                category: category
            }
        }, callbackOk, callbackErr);
    }

    //</editor-fold>


    //<editor-fold desc="Methods Administrator">
    static async adminLoginUser(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, username: string, password: string) {
        return this.handlePromise('admin/loginUser', {
            params: {
                username: username,
                password: password,
            }
        }, callbackOk, callbackErr);
    }

    static async adminLoginToken(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, token: string): Promise<AxiosResponse> {
        return this.handlePromise('admin/loginToken', {
            params: {token: token}
        }, callbackOk, callbackErr);
    }

    static async adminImportCalendar(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, token: string | null, dateFromMs: number, dateToMs: number): Promise<AxiosResponse> {
        return this.handlePromise(
            'admin/importCalendar', {
                params: {
                    token: token,
                    dateFrom: dateFromMs,
                    dateTo: dateToMs
                }
            }, callbackOk, callbackErr);
    }

    static async adminImportNameDays(callbackOk: ((n: AxiosResponse) => void) | null, callbackErr: ((n: AxiosError) => void) | null, token: string | null): Promise<AxiosResponse> {
        return this.handlePromise(
            'admin/importNameDays', {
                params: {token: token}
            }, callbackOk, callbackErr);
    }

    static async adminSaveConfig(callbackValue: ((n: AxiosResponse) => void) | null, callbackError: ((n: AxiosError) => void) | null, formData: any): Promise<AxiosResponse> {
        return this.handleResponse(
            prefix + "admin/config/store",
            RestClientApergia.toPost('admin/config/store', formData),
            callbackValue,
            callbackError
        )
    }

    static async adminSaveContent(callbackValue: ((n: AxiosResponse) => void) | null, callbackError: ((n: AxiosError) => void) | null, formData: any): Promise<AxiosResponse> {
        return RestClientApergia.handleResponse(
            prefix + "admin/content/store",
            RestClientApergia.toPost('admin/content/store', formData),
            callbackValue,
            callbackError
        )
    }

    static async adminSaveEvents(callbackValue: ((n: AxiosResponse) => void) | null, callbackError: ((n: AxiosError) => void) | null, formData: any):Promise<AxiosResponse> {
        return RestClientApergia.handleResponse(
            prefix + "events/eventUpdate",
            RestClientApergia.toPost('events/eventUpdate', formData),
            callbackValue,
            callbackError
        )
    }

    //</editor-fold>

    //<editor-fold desc="Methods Utilities">
    static handlePromise(url: string,
                         config: any = null,
                         callbackOk: ((n: AxiosResponse) => void) | null,
                         callbackEr: ((n: AxiosError) => void) | null,
                         delay: number = 0) : Promise<AxiosResponse>{

        UtilitiesApp.Log(LN + "Handle request:" + prefix+url + " [delay]=" + delay);

        let promiseAxios: Promise<AxiosResponse> = config != null ? axios.get(prefix + url, config) : axios.get(prefix + url);
        return this.handleResponse(url, promiseAxios, callbackOk, callbackEr, delay);
    }

    static handleResponse(logPrefix: string,
                          promiseAxios: Promise<AxiosResponse>,
                          callbackOk: ((n: AxiosResponse) => void) | null,
                          callbackEr: ((n: AxiosError) => void) | null,
                          delay: number = 0): Promise<AxiosResponse> {
        promiseAxios
            .then(response => {
                UtilitiesApp.Log(LN + logPrefix + ":(ok)");
                if (callbackOk != null)
                    callbackOk(response);
            })
            .catch(response => {
                console.log(response);
                UtilitiesApp.Log(LN + logPrefix + ":(error)", response);
                if (callbackEr != null)
                    callbackEr(response)
            });
        //.then(value => new Promise((resolve, reject) => setTimeout(() => resolve(value), delay)))
        return promiseAxios;
    }

    static handlePromise2(url: string, promiseAxios: Promise<AxiosResponse>, callbackOk: ((n: AxiosResponse) => void) | null, callbackEr: ((n: AxiosError) => void) | null, delay: number = 0) {
        UtilitiesApp.Log(LN + "Handle request:" + url + " [delay]=" + delay);
        promiseAxios
        //.then(value => new Promise((resolve, reject) => setTimeout(() => resolve(value), delay)))
            .then(response => {
                UtilitiesApp.Log(LN + url + ":(ok)");
                if (callbackOk != null)
                    callbackOk(response);
            })
            .catch(response => {
                UtilitiesApp.Log(LN + url + ":(error)", response);
                if (callbackEr != null)
                    callbackEr(response)
            });
        return promiseAxios;
    }

    static toPost(url: string, data: any) {
        //@ts-ignore
        return axios({
            method: 'post',
            url: prefix + url,
            data: data,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        });
    }

    static createFormData(data: any): FormData {
        let formData = new FormData();
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                formData.append(k, data[k]);
            }
        }
        return formData;
    }

    //</editor-fold>
}
