










































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {EventStructure} from "@/model/ModelApp";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {UtilDate, UtilitiesApp, Language} from "@/utilities/UtilitiesApp";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Component({})
export default class extends mixins(ComponentBasic) {
    LN: string = "[PageEvent]::";
    payload: PayloadStructure<Object> = PayloadStructure.loading(Language.pleaseWait);
    listEvents: Array<EventStructure> | null = null; /*from payload the events*/
    countOfFutureEvents: number = 0;
    textFilter: string = '';
    isViewAll: boolean = false;
    color: string = 'white';

    get hasCommonSideBar() {
        return !UtilitiesBasic.isMobile();
    }

    calcContent() {
        this.payload = PayloadStructure.loading(Language.pleaseWait);

        this.GetEventsNameDaysPromise()
            .then((event) => {
                this.payload = PayloadStructure.info(event);
                this.listEvents = event != null ? event.listEvents : null;
                if (this.listEvents) {
                    this.listEvents = Array.from(this.listEvents,
                        e => {
                            e.title = e.title.replace("Γιορτή:", "");
                            //@ts-ignore
                            e.daysFromNow = UtilDate.getDiffInDays(new Date(), e.getDateFrom());

                            if (e.daysFromNow <= 0) {
                                //@ts-ignore
                                let dateNextHoliday = new Date(e.getDateFrom());
                                dateNextHoliday.setFullYear(dateNextHoliday.getFullYear() + 1);
                                e.daysFromYearNext = UtilDate.getDiffInDays(new Date(), dateNextHoliday);
                            } else {
                                this.countOfFutureEvents++;
                            }
                            return e;
                        });
                }
            }).catch((error) => {
                this.payload = PayloadStructure.error(Language.error)
            }
        );
    }

    filterEvents() {
        let filterLower = UtilitiesApp.toStringNoAccent(this.textFilter.toLowerCase());
        this.Log("Filtering [" + filterLower + "]");
        //@ts-ignore
        return this.listEvents.filter(e => this.textFilter.trim() == "" || UtilitiesApp.toStringNoAccent(e.title.toLowerCase()).includes(filterLower));
    }

    /*getDaysDiff(date, isThisYear = true) {
        let dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);
        if (!isThisYear) {
            dateNow.setYearToDateFrom(dateNow.getFullYear() + 1);
        }
        return Math.round((date - dateNow) / (24 * 60 * 60 * 1000));
    }*/

    created() {
        this.calcContent();
    }

    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }


}
