
















































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {EventStructure} from "@/model/ModelApp";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {Language} from "@/utilities/UtilitiesApp";

@Component({})
export default class ListYoutube extends Mixins(ComponentBasic) {
    LN: string = "[ListYoutube]::";
    listFiltered: EventStructure[] = [];
    payload: PayloadStructure<Map<string, EventStructure[]>> = PayloadStructure.loading(Language.pleaseWait);
    fontSize: string = '14px';

    @Prop({default: "gr"})
    category!: string;

    @Prop({default: true})
    selectable!: boolean;

    @Prop({default: false})
    showVideo!: boolean;

    @Prop({default: null})
    listEvents!: [] | null;

    @Prop({default: null})
    height!: string | null;

    @Prop({default: null})
    day!: string | null;

    @Prop({default: '28px'})
    imgSize!: string;

    @Prop({default: '450px'})
    heightDiv!: string;

    @Prop({default: false})
    showToolbar!: boolean;

    created() {
        this.loadData();
    }

    onClick(event: EventStructure) {
        this.Log("on click video id");
        this.EventThrow('dialog', {title: event.title, urlVideo: 'https://www.youtube.com/embed/' + event.idSource})
    }

    goTo(url: string) {
        this.$router.push({path: url});
    }

    @Watch("category")
    onCategory() {
        this.loadData();
    }

    async loadData() {
        try {
            this.payload = PayloadStructure.loading(Language.pleaseWait);
            this.payload = await RestClientApergia.getTrendYoutube();
            //@ts-ignore
            if (this.payload.getPayload() != null && this.payload.getPayload().has(this.category))
            //@ts-ignore
                this.listFiltered = this.payload.getPayload().get(this.category) || [];
            else
                this.listFiltered = [];
            //@ts-ignore
            //this.listFiltered.sort((a, b) => a.dateCreatedMs < b.dateCreatedMs ? 1 : -1)

        } catch (error) {
            this.payload = PayloadStructure.error("Failed");
        }
    }
}

