





























import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";

@Component({})
export default class ComponentDialog extends mixins(ComponentBasic) {
    LN: string = "[ComponentDialog]::";

    @Prop({default: false})
    visible!: boolean;

    @Prop({default: null})
    content!: string | null;

    @Prop({default: null})
    title!: string | null;

    isVideo = false;
    urlVideo: string | null = null;

    created() {
        this.EventRegister('dialog', this.OnDialog);
    }

    onHide(){
        this.visible = false;
        this.isVideo = false;
    }

    OnDialog(message: { title: string, content?: string, urlVideo?: string }) {
        this.Log("On Dialog Display");
        this.visible = true;
        this.isVideo = false;
        this.title = message.title;
        this.content = message.content || '';
        if (message.urlVideo) {
            this.isVideo = true;

       this.urlVideo = message.urlVideo;
        }
    }
}
