



































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Component, Mixins, Prop} from "vue-property-decorator";
import {EventStructure} from "@/model/ModelApp";
import {Language, UtilDate, UtilitiesApp} from "@/utilities/UtilitiesApp";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

@Component({})
export default class ListNamedays extends Mixins(ComponentBasic) {
  LN: string = "[ListNamedays]::";
  payload: PayloadStructure<Object> = PayloadStructure.loading(Language.pleaseWait);
  listEvents: Array<EventStructure> | null = null; /*from payload the events*/
  countOfFutureEvents: number = 0;
  textFilter: string = '';
  color: string = 'white';

  @Prop({default: false})
  isViewAll!: boolean;

  @Prop({default: false})
  isDense!: boolean;

  @Prop({default: true})
  isShowFilter!: boolean;

  @Prop({default: '100%'})
  heightPx!: string;

  mounted() {
    this.calcContent();
  }

  calcContent() {
    this.Log("Loading ListNamedays, isDense:" + this.isDense)
    this.payload = PayloadStructure.loading(Language.pleaseWait);

    this.GetEventsNameDaysPromise()
        .then((event) => {
          this.payload = PayloadStructure.info(event);
          this.listEvents = event != null ? event.listEvents : [];
          this.Log("Loaded namedays length " + this.listEvents.length);
          if (this.listEvents.length > 0) {
            this.listEvents = Array.from(this.listEvents,
                e => {
                  e.title = e.title.replace("Γιορτή:", "");
                  //@ts-ignore
                  e.daysFromNow = UtilDate.getDiffInDays(new Date(), e.getDateFrom());

                  if (e.daysFromNow <= 0) {
                    //@ts-ignore
                    let dateNextHoliday = new Date(e.getDateFrom());
                    dateNextHoliday.setFullYear(dateNextHoliday.getFullYear() + 1);
                    e.daysFromYearNext = UtilDate.getDiffInDays(new Date(), dateNextHoliday);
                  } else {
                    this.countOfFutureEvents++;
                  }
                  return e;
                });
          }
          this.Log("Loaded namedays length " + this.listEvents.length);
        }).catch((error) => {
          this.payload = PayloadStructure.error(Language.error)
        }
    );
  }

  filterEvents() {
    let filterLower = UtilitiesApp.toStringNoAccent(this.textFilter.toLowerCase());
    this.Log("Filtering [" + filterLower + "]");
    if (!this.listEvents)
      return [];
    //@ts-ignore
    return this.listEvents.filter(e => this.textFilter.trim() == "" || UtilitiesApp.toStringNoAccent(e.title.toLowerCase()).includes(filterLower));
  }


}

