<template>
    <v-app-bar color="black" fixed  dark>

        <v-toolbar-title @click="GoToRouter()" style="cursor: pointer">
            <img src="extra/img/logo/logo.png"/>
        </v-toolbar-title>

        <template v-if="isTopBarCompact">
            <v-col cols="1">
                <span class="caption">&nbsp;&nbsp;{{date}}</span>
            </v-col>
            <v-col cols="4">
                <v-text-field
                        box
                        label="Αναζήτηση Απεργιών"
                        prepend-icon="search"
                        v-model="textSearch"
                        @keyup.enter.native="onSearch"
                        clearable></v-text-field>
            </v-col>
        </template>

        <template v-if="!isTopBarCompact">
            <v-toolbar-items class="hidden-sm-and-down">

                <!-- Home -->
                <v-btn text class="apergia-btn white--text" @click="GoToRouter()">
                    <v-icon left dark>mdi-home</v-icon>
                    <span v-if="isShowText">Ημερολογιο</span>
                </v-btn>

                <!-- Info -->
                <v-menu open-on-hover offset-y>

                    <template v-slot:activator="{ on }">
                        <v-btn class="apergia-btn" slot="activator" text v-on="on">
                            <v-icon left dark>info</v-icon>
                            <span v-if="isShowText">Πληροφοριες</span></v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item @click="GoToRouter(TypePath.NameDays.path)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">card_giftcard</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Εορτολόγιο</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="GoToRouter(TypePath.Content.path+'/'+TypeContent.Phones)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">phone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Χρησιμά Τηλέφωνα</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="GoToRouter(TypePath.Holidays.path)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">hotel</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content class="link-menu">Επίσημες Αργίες</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="GoToRouter(TypePath.Market.path)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">shopping_basket</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Καταστήματα</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="GoToRouter(TypePath.Weather.path)">
                            <v-list-item-icon>
                                <li class="wi wi-day-cloudy-windy blue--text" style="font-size:20px"></li>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Καιρός</v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item @click="GoToRouter(TypePath.Archive.path)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Αναζήτηση Απεργιών</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="GoToRouter(TypePath.Stats.path)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">show_chart</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Στατιστικά Απεργιών</v-list-item-content>
                        </v-list-item>

                        <v-divider ></v-divider>

                        <v-list-item @click="GoToRouter(TypePath.Content.path+'/'+TypeContent.InfoRoutes)">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">departure_board</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Δρομολόγια</v-list-item-content>
                        </v-list-item>

                        <v-list-item href="https://www.athenacard.gr/komistra.dev">
                            <v-list-item-icon>
                                <v-icon v-bind:color="hasColor ? 'blue' : ''">euro_symbol</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="link-menu">Τιμές Εισητηρίων</v-list-item-content>
                        </v-list-item>

                    </v-list>
                </v-menu>

                <!-- Apps -->
                <v-btn text class="apergia-btn white--text"
                       @click="GoToRouter(TypePath.Content.path+'/'+TypeContent.Apps)">
                    <v-icon left>mdi-android</v-icon>
                    <span v-if="isShowText">Apps</span>
                </v-btn>

                <!-- Contact -->

                <v-spacer></v-spacer>


            </v-toolbar-items>

        </template>

        <v-spacer></v-spacer>

        <v-text-field v-if="isShowSearchBox && !isTopBarCompact"
                      single-line
                      label="Αναζήτηση Απεργίας"
                      v-model="textSearch"
                      prepend-icon="search"
                      @keyup.enter.native="onSearch"
                      clearable></v-text-field>

        <!--<v-btn color="green" fab small dark style="margin:8px" @click="isShowSearchBox=!isShowSearchBox">-->
        <v-btn color="green" fab small dark style="margin:8px" @click="GoToRouter(TypePath.Archive.path)">
            <i class="fas fa-search"></i>
        </v-btn>

        <v-btn color="blue" fab small dark style="margin:8px" target="_blank"
               href="https://www.facebook.com/%CE%97%CE%BC%CE%B5%CF%81%CE%BF%CE%BB%CF%8C%CE%B3%CE%B9%CE%BF-%CE%91%CF%80%CE%B5%CF%81%CE%B3%CE%B9%CF%8E%CE%BD-323954714290073/">
            <i class="fab fa-facebook-f"></i>
        </v-btn>

        <v-menu :open-on-hover="true" offset-y>

            <template v-slot:activator="{ on }">
                <v-btn color="red" fab small dark style="margin:8px" slot="activator" v-on="on">
                    <i class="fas fa-envelope"></i>
                </v-btn>
            </template>

            <v-list>
                <v-list-item @click="GoToRouter(TypePath.Content.path+'/'+TypeContent.WhoWeAre)">
                    <v-list-item-icon>
                        <v-icon v-bind:color="hasColor ? 'red' : ''">mdi-contacts</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Ποιοι Ειμαστε</v-list-item-content>
                </v-list-item>
                <v-list-item @click="'mailto:info@apergia.gr'">
                    <v-list-item-icon>
                        <v-icon v-bind:color="hasColor ? 'red' : ''">mdi-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="link-menu">Επικοινωνία</v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>
</template>
<script>
    import {ComponentBasic} from "@/utilities/UtilitiesVue";
    import moment from 'moment'
    import {UtilitiesApp} from "@/utilities/UtilitiesApp";
    import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

    export default {
        mixins: [ComponentBasic],
        data() {
            return {
                LN: "[NavigationPublic]::",
                isShowText: !UtilitiesBasic.isMobile(),
                hasColor: true,
                textSearch: '',
                isShowSearchBox: false,
                isTopBarCompact: UtilitiesApp.staticSettings().isTopBarCompact,
            }
        },
        computed: {
            date() {
                moment.locale("el");
                return moment().format('dddd D MMMM ')
            }
        },
        methods: {
            onSearch() {
                this.Log("Search for [" + this.textSearch + "]");
                this.$router.push({name: TypePath.Archive.path, params: {search: this.textSearch}});
            }
        },

        watch: {
            // eslint-disable-next-line
            '$route'(to, from) {
                this.Log("route updated?");
                this.textSearch = '';
            },
            // eslint-disable-next-line
            getSettings(val) {
                this.isTopBarCompact = this.getSettings.isTopBarCompact;
            }

        }
    }
</script>
<style scoped>

    .apergia-btn {
        font-size: 14px;
        letter-spacing: normal;
        -webkit-box-shadow: none;
        box-shadow: none;
    }


    .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #000000;
    }
</style>
