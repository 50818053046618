<template>
    <div style="height:100%">
<!--        <script2 async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" crossorigin="anonymous"></script2>-->
        <script2 async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9069971152084394" crossorigin="anonymous"></script2>

        <template v-if="type==='bar'">
            <!-- vue2-970.90bar -->
            <ins class="adsbygoogle"
                 style="display:inline-block;width:970px;height:90px"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="5875885353"></ins>
        </template>

        <template v-else-if="type==='box-big'">
            <!-- vue2-336.280box -->
            <ins class="adsbygoogle"
                 style="display:inline-block;width:336px;height:280px"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="2675006940">
            </ins>
        </template>

        <template v-else-if="type==='auto'">
            <!-- vue2-automatic -->
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="2621531398"
                 data-ad-format="auto"
                 data-full-width-responsive="true">
            </ins>
        </template>

        <template v-else-if="type==='dynamic-box'">
            <!-- vue2-automatic -->
<!--            <ins class="adsbygoogle adslot_1"
                 style="display:inline-block"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="2621531398">
            </ins>-->
          <ins class="adsbygoogle"
               style="display:block"
               data-ad-client="ca-pub-9069971152084394"
               data-ad-slot="2621531398"
               data-ad-format="auto"
               data-full-width-responsive="true">
          </ins>
        </template>

        <template v-else-if="type==='native-responsive-content'">
<!--            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-format="autorelaxed"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="1739877953">
            </ins>-->
          <ins class="adsbygoogle"
               style="display:block"
               data-ad-format="autorelaxed"
               data-ad-client="ca-pub-9069971152084394"
               data-ad-slot="1739877953">
          </ins>
        </template>

      <!--
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9069971152084394"
              crossorigin="anonymous"></script>

      <ins class="adsbygoogle"
           style="display:block"
           data-ad-client="ca-pub-9069971152084394"
           data-ad-slot="2621531398"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
      -->

        <!-- <style type="text/css">
             .adslot_1 { width: 320px; height: 100px; }
             @media (min-width:500px) { .adslot_1 { width: 468px; height: 60px; } }
             @media (min-width:800px) { .adslot_1 { width: 728px; height: 90px; } }
         </style>
         <ins class="adsbygoogle adslot_1"
              style="display:inline-block;"
              data-ad-client="ca-pub-1234"
              data-ad-slot="5678"></ins>
         <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
         <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>-->

        <template v-else>
            <!-- vue2-250.250box -->
            <ins class="adsbygoogle"
                 style="display:inline-block;width:250px;height:250px"
                 data-ad-client="ca-pub-9069971152084394"
                 data-ad-slot="8579063911">
            </ins>
        </template>

        <script2>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script2>
    </div>
</template>
<script>
    export default {
        data() {
            return {}
        },
        props: {
            type: String,
        }
    }
</script>

<style>
    .adslot_1 {
        width: 250px;
        height: 250px;
    }

    @media (max-width: 1900px) {
        .adslot_1 {
            width: 300px;
            height: 250px;
        }
    }

    @media (max-width: 1256px) {
        .adslot_1 {
            width: 180px;
            height: 150px;
        }
    }

    @media (max-width: 800px) {
        .adslot_1 {
            width: 125px;
            height: 125px;
        }
    }
</style>

