












































































































import {Prop, Watch} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
import {Language} from "@/utilities/UtilitiesApp";
import {UtilitiesMath} from "@/utilities/basic/UtilitiesMath";

@Component({})
export default class ChartCovnid19 extends mixins(ComponentBasic) {
    LN: string = "[ChartConvid19]";
    title: string = '';

    @Prop({default: false})
    isShowBar!: boolean;

    payload: PayloadStructure<any> = PayloadStructure.loading(Language.pleaseWait);
    chartType: string = 'Spline';
    isShowNumbers: boolean = false;
    isChartColumn: boolean = false;
    isPlotPerMillion: boolean = false;
    isPlotIncremental: boolean = true;
    isShowNumberInChart: boolean = false;

    /*Trick to regenerate the chart when needed*/
    refreshIndex: number = 0;

    /*The cached Data*/
    dataPlot: { [key: string]: number[][] } = {};

    countries: { key: string, color: string, name: string, code: string }[] = [
        {key: "Greece", color: "0034ff", name: "Ελλάδα", code: "gr"},
        {key: "Italy", color: "bd00ff", name: "Ιταλία", code: "it"},
        {key: "Russia", color: "ff00b4", name: "Ρωσσία", code: "ru"},
        {key: "France", color: "9fff00", name: "Γαλλία", code: "fr"},
        {key: "Belgium", color: "9fff00", name: "Βέλγιο", code: "be"},
        {key: "Sweden", color: "00ff1a", name: "Σουηδία", code: "se"},
        {key: "Switzerland", color: "ff0033", name: "Ελβετία", code: "ch"},
        {key: "Finland", color: "ff0033", name: "Φιλανδία", code: "fi"},
        {key: "South_Korea", color: "ba92dc", name: "Νότια Κορέα", code: "kr"},
        {key: "Spain", color: "30d6dc", name: "Ισπανία", code: "es"},
        /*{key: "United_States_of_America", color: "ff6800", name: "Αμερική", code: "us"},*/
        {key: "United States of America", color: "ff6800", name: "Αμερική", code: "us"},
        {key: "The United Kingdom", color: "dad500", name: "Αγγλία", code: "gb"},
        /*{key: "United_Kingdom", color: "dad500", name: "Αγγλία", code: "gb"},*/
        {key: "Netherlands", color: "a3d300", name: "Ολλανδία", code: "nl"},
        {key: "China", color: "ff0033", name: "Κίνα", code: "cn"},
    ];

    /*The mode of the list-box, code is thow letter code, key: is the dataPlot*/
    countriesModel: { name: string, color: string, code: string[], key: string, selected: boolean, total: number, pop: number, is_d: boolean }[] = [];

    created() {
        this.loadData();
        //Generate Model From Countries

    }

    @Watch('countriesModel')
    onCountriesSelected() {
        this.Log(this.LN, "changed..countries");
        this.refreshIndex++;
    }


    @Watch('isShowNumbers')
    onShowNumbers() {
        this.refreshIndex++;
    }


    get hasCommonSideBar() {
        return this.isShowBar;
    }

    async loadData() {

        this.countriesModel = [];

        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.payload = await RestClientApergia.getConvid19();
        let data: any = this.payload.payload

        for (let country of this.countries) {
            let dataCountry = data[country.key];
            this.Log("Reading Data of country " + country.key + " - " + (data[country.key] != null));
            if (data[country.key] != null) {
                this.dataPlot[country.code + '_d'] = this.arrayOfCountry(country.key, data, false, false, false);
                this.dataPlot[country.code + '_d_acc'] = this.arrayOfCountry(country.key, data, false, true, false);
                this.dataPlot[country.code + '_d_rel'] = this.arrayOfCountry(country.key, data, false, false, true);
                this.dataPlot[country.code + '_d_acc_rel'] = this.arrayOfCountry(country.key, data, false, true, true);
                this.dataPlot[country.code + '_c'] = this.arrayOfCountry(country.key, data, true, false, false);
                this.dataPlot[country.code + '_c_acc'] = this.arrayOfCountry(country.key, data, true, true, false);
                this.dataPlot[country.code + '_c_rel'] = this.arrayOfCountry(country.key, data, true, false, true);
                this.dataPlot[country.code + '_c_acc_rel'] = this.arrayOfCountry(country.key, data, true, true, true);

                let selectedD = country.code == 'gr'; /*|| country.code == 'it' || country.code == 'kr' || country.code == 'fr' || country.code == 'ch' || country.code == 'us' || country.code == 'ru'*/
                let selectedC = country.code == 'gr'; //false;//country.code == 'gr' || country.code == 'it' || country.code == 'kr' || country.code == 'fr'
                //Read the accumulated data to make the per1000 values - undefined ?
                let total_d = this.dataPlot[country.code + '_d_acc'][this.dataPlot[country.code + '_d_acc'].length - 1][1];
                let total_c = this.dataPlot[country.code + '_c_acc'][this.dataPlot[country.code + '_c_acc'].length - 1][1];

                let cases = {name: country.name + " Κρουσματα (" + UtilitiesMath.roundTwo(total_c / (dataCountry.pop / 1000)) + " ανά 1000)", color: '#' + country.color, code: country.code, key: country.code + "_c", selected: selectedC/*|| country.code == 'it'*/, pop: dataCountry.pop, total: total_c, is_d: false};
                let deaths = {name: country.name + " Θάνατοι (" + UtilitiesMath.roundTwo(total_d / (dataCountry.pop / 1000)) + " ανά 1000)", color: UtilitiesBasic.getStringToColor(country.color + 'white'), code: country.code, key: country.code + "_d", selected: selectedD /*|| country.code == 'it'*/, pop: dataCountry.pop, total: total_d, is_d: true};

                //@ts-ignore
                this.countriesModel.push(cases);
                //@ts-ignore
                this.countriesModel.push(deaths);
            }
        }
        this.refreshIndex++;

    }

    /**
     * Two dimension array
     * [[date-ms,number] , [date-ms,number], [date-ms,number]]
     * @param country
     * @param data
     * @param isCases
     * @param isIncremental
     */
    arrayOfCountry(country: string, data: any, isCases: boolean = true, isIncremental: boolean = false, isRelativePerMillion: boolean = false): number[][] {
        let result: number[][] = [];
        //console.log(country);
        if (data[country] != null) {
            let pop = data[country].pop;
            let relativeAcc = 1000000 / pop;
            let countryInput: Array<Array<number>> = data[country].data;
            countryInput.sort((a, b) => a[0] > b[0] ? 1 : -1);
            let valueResult: number = 0;
            for (let item of countryInput) {
                let value = isCases ? item[1] : item[2];
                value = isRelativePerMillion ? (relativeAcc * value) : value;
                valueResult = isIncremental ? (valueResult + value) : value;
                //todo making Math ABS
                if (!isIncremental && valueResult < 0) {
                    valueResult = 0;
                }
                //if (country == "Greece" && !isIncremental && isCases) {
                //console.log(`${new Date(item[0])}:${valueResult}`)
                //}
                result.push([item[0], UtilitiesMath.roundOne(valueResult)]);
            }
        }
        return result;
    }


    get plotBandsGreece(): any {
        let plotBands = [{
            from: Date.UTC(2020, 3, 23),
            to: Date.UTC(2020, 5, 11),
            color: 'rgba(212,215,115,0.26)',
            label: {
                text: '<em>Lockdown</em><br>Ελλάδα <br>23 Μαρ-~11 Μαϊ',
                style: {
                    color: '#999999'
                },
                y: 180
            }
        }, {
            from: Date.UTC(2020, 11, 7),
            to: +Date.now(),
            color: 'rgba(212,215,115,0.26)',
            label: {
                text: '<em>Lockdown</em><br>Ελλάδα <br>7 Νοε - ',
                style: {
                    color: '#999999'
                },
                y: 30
            }
        }
        ]
        return plotBands;
    }

    get chartOptions(): any {
        this.Log("Regenerate chartOptionsColumn (data are altered)" + this.chartType);
        let isLegend: boolean = false;
        let options = {
            chart: {
                type: (this.isChartColumn ? "column" : "line"),
                zoomType: 'x'
            },
            title: {text: ''},
            subtitle: {text: ''},
            /*,
            tooltip: {
                crosshairs: true,
                shared: true
            }*/
            plotOptions: {
                column: {},
                line: {
                    dataLabels: {
                        enabled: this.isShowNumbers,
                        format: '{y}'
                    },
                    enableMouseTracking: true,
                    column: {
                        dataLabels: {enabled: true},
                        enableMouseTracking: true
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                plotBands: this.plotBandsGreece,
                title: {text: ''}
            },
            yAxis: {
                title: {text: ''}
            },
            tooltip: {
                shared: true,
                enabled: !this.isShowNumbers
            },
            legend: isLegend ? {
                    enabled: false,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                } :
                {enabled: false},
            refresh: this.refreshIndex,
            series: this.generateChartSeries()
        };

        return options;
    }


    /**
     * DO Not alter it to getter
     */
    generateChartSeries(): any[] {
        let series = [];
        for (let country of this.countriesModel) {
            series.push(
                {
                    data: this.dataPlot[country.key + (this.isPlotIncremental ? '_acc' : '') + (this.isPlotPerMillion ? '_rel' : '')],
                    color: country.color,
                    name: country.name /*+ ' κρούσματα'*/,
                    visible: country.selected
                }
            );
        }
        return series;
    }

    /*,
    seriesColor(newValue) {
        this.chartOptions.series[0].color = newValue.toLowerCase()
    }*/
}
