
















































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";

@Component({})
export default class ComponentFooter extends mixins(ComponentBasic) {
    LN: string = "[ComponentFooter]::";
    policy: { title: string, content: string } = {
        title: "Περιορισμός Ευθύνης",
        content: "Στο κείμενο που ακολουθεί, περιλαμβάνονται οι προϋποθέσεις επίσκεψης, συνεργασίας, και οι όροι χρήσης του δικτυακού μας τόπου καθώς και όλων των εφαρμογών που παρέχονται μέσω του δικτυακού τόπου όπως apergia.gr rss feeds, apergia.gr facebook application page, apergia.gr twitter page, apergia.gr mobile applications, apergia.gr widget, apergia.gr calendar και οποιασδήποτε άλλης εφαρμογής που ενημερώνεται ή παρέχεται από το δικτυακό μας τόπο (εφεξής θα αναφέρονται ως \"εφαρμογες\"). Ο επισκέπτης – χρήστης των σελίδων του δικτυακού μας τόπου και των εφαρμογών της ιστοσελίδας, αφού διαβάσει το κείμενο που ακολουθεί, και αποδεχθεί τους όρους χρήσης, που είναι υποχρεωτικοί για όλους τους επισκέπτες – χρήστες, μπορεί να επισκεφθεί ή να κάνει χρήση των σελίδων ή των υπηρεσιών του δικτυακού αυτού τόπου." +
            "<br/><br/>" +
            "To apergia.gr κάνει κάθε προσπάθεια για να εξασφαλίσει την ακρίβεια των στοιχείων στην ιστοσελίδα καθώς και σε όλες τις εφαρμογές του. Παρόλα αυτά, σε περίπτωση λανθασμένων πληρoφoριών, τo apergia.gr δεν έχει καμία νομική ευθύνη για oπoιαδήπoτε ζημιά που πιθανόν προκύψει από λάθη στην ιστοσελίδα του ή στις εφαρμογές του. Επίσης η ιστοσελίδα και οι εφαρμογές μας περιέχουν συνδέσμoυς σε άλλες ιστοσελίδες. To apergia.gr σε καμία περίπτωση δεν ευθύνεται για τo περιεχόμενο των ιστοσελίδων αυτών. Τέλος το apergia.gr διατηρεί το δικαίωμα να σταματήσει να παρέχει την οποιαδήποτε υπηρεσία του ανα πάσα στιγμή και για τον οποιονδήποτε λόγο.\n" +
            "<br/><br/>" +
            "Για δημοσιευμένο υλικό το οποίο ανήκει σε τρίτους αναφέρεται ευκρινώς η πηγή με την πλήρη διεύθυνση (url). Αν έχετε ενδείξεις ότι έχουν παραβιαστεί πνευματικά δικαιώματα τρίτων, παρακαλώ επικοινωνήστε μαζί μας στο info@apergia.gr ώστε να εξεταστεί το αίτημά σας άμεσα."
    }
};
