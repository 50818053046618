<!--
- List events passed as property ('list-events') OR
- Load events of the input property ('day')
-->
<template>


    <v-col cols=12 style="padding:1px">

      <template v-if="showToolbar">
        <v-toolbar :color="color!=null ? color : 'light-blue'" dark v-if="title">
          <!--<v-toolbar-side-icon></v-toolbar-side-icon>-->
          <v-toolbar-title class="text-md-center">{{title}}</v-toolbar-title>
        </v-toolbar>
      </template>

      <payload-message-component :payload="payload"
                                 v-if="payload.getType() != TypeMessage.Info"
                                 style="width:100%;height:100%;vertical-align:middle;padding:30px;"></payload-message-component>


      <v-list :two-line="showDateEvent" style="padding:1px;user-select:auto" v-if="payload.getType() == TypeMessage.Info">

        <v-list-item v-if="showDateTitle" class="user_select">
          <v-list-item-content><span :style="'width:100%;font-size:'+headerFontSize+';text-align:center'">{{UtilDate().toStringCustom(getDate(),"dddd, Do MMMM")}}</span></v-list-item-content>
        </v-list-item>

        <slot name="header">

        </slot>

        <template v-for="event in listFiltered">
          <v-divider v-if="showDivider"></v-divider>
          <v-list-item
                  class="user_select"
                  :style="height!=null ? 'height:'+height :  'height:48px;' + ';user-select:auto'"
                  @click="GoToRouter(Util.UtilitiesApp.toURLEvent(event))"
                  :key="event.id">

            <v-list-item-avatar>
              <img :src="Util.UtilitiesApp.toImageSubCategoryFromEvent(event)" :style="'width:'+ imgSize +';height:'+imgSize">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title :style="'font-size:'+textFontSize">{{event.title}}</v-list-item-title>
              <a class='small-link' v-if="showDateEvent" v-html="Util.UtilitiesApp.toEventDatePretty(event, showYear)"></a>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-action>
              <v-btn icon ripple :href="event.url">
                <v-icon color="grey lighten-1" v-if="event.url">link</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="showDivider"></v-divider>
        </template>

        <slot name="footer">

        </slot>
      </v-list>

    </v-col>


</template>
<script>
    import {ComponentBasic} from "@/utilities/UtilitiesVue";
    import {PayloadStructure} from "@/model/basic/ModelBasic";
    import {Language, UtilDate, UtilitiesApp} from "@/utilities/UtilitiesApp";

    export default {
        mixins: [ComponentBasic],
        props: {
            listEvents: Array,
            height: String,
            day: String,
            showYear: Boolean,
            showToolbar: Boolean,
            showDateTitle: {type:Boolean,default:true},
            showDateEvent: {type:Boolean,default:true},
            showDivider: {type:Boolean,default:true},
            headerFontSize: {default: '1.3rem', type: String},
            textFontSize: {default: '0.97rem', type: String},
            imgSize: {
                default: '48px',
                type: String
            }
        },
        data() {
            return {
                LN: "[LinkBarList]::",
                listFiltered: [],
                payload: PayloadStructure.loading(Language.pleaseWait),
            }
        },
        watch: {
            day: function () {
                this.getEventsOfDay();
            }
        },
        computed: {},
        methods: {

            getDate: function () {
                return UtilitiesApp.toURLDateFromString(this.day);
            },
            getEventsOfDay() {
                if (this.listEvents != null) {
                    this.payload = PayloadStructure.info(this.listEvents);
                    this.listFiltered = this.listEvents;
                } else {
                    let dateFrom = UtilitiesApp.toURLDateFromString(this.day);
                    this.title = UtilDate.toStringDatePretty(dateFrom, true);
                    if (!(dateFrom instanceof Date)) {
                        dateFrom = new Date();
                        dateFrom.setHours(0, 0, 0, 0);
                    }
                    let dateTo = new Date(dateFrom.getTime());
                    dateTo.setDate(dateTo.getDate() + 1);
                    this.GetEventSearchPromise(dateFrom, dateTo).then(eventWrapper => {
                        this.payload = PayloadStructure.info(eventWrapper);
                        this.listFiltered = eventWrapper.listEvents
                    });
                }
            }
        },
        created() {
            this.getEventsOfDay();
        }
    }

</script>
<style>
  .user_select {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .v-list__tile--link {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
</style>
