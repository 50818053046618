























































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {UtilitiesApp} from "@/utilities/UtilitiesApp";
import {Vue, Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EventStructure} from "@/model/ModelApp";

@Component({})
export default class ListSummary extends mixins(ComponentBasic) {
    LN: string = "[ListSummary]::";

    @Prop({default: '2'})
    countNamedays!: String;

    @Prop({default: '60px'})
    heightPx!: String;

    @Prop({default: '42px'})
    imgSizePx!: String;

    @Prop({default: true})
    showMarket!: boolean;

    @Prop({default: true})
    showHoliday!: boolean;

    @Prop({default: true})
    showSunday!: boolean;

    @Prop({default: true})
    showTomorrow!: boolean;

    @Prop({default: true})
    showToday!: boolean;

    @Prop({default: true})
    showOthers!: boolean;

    @Prop({default: true})
    showWeather!: boolean;

    @Prop({default: false})
    showCountdown!: boolean;

    /*Computed Properties*/
    get closestHoliday(): EventStructure {
        return UtilitiesApp.getHolidayClosest();
    }

    get strikeEventToday(): EventStructure {
        return UtilitiesApp.toSummaryEvent(this.summary!.getStrikeToday());
    }

    get strikeEventTomorrow(): EventStructure {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        return UtilitiesApp.toSummaryEvent(this.summary!.getStrikeTomorrow(), date);
    }

    get strikeEventFuture(): EventStructure {
        let date = new Date();
        return UtilitiesApp.toSummaryEvent(this.summary!.getStrikesAfter(date), date, 'Όλες οι Ενημερώσεις');
    }

    get salesEvent(): EventStructure | null {
        if (this.settings != null && this.settings.getMarketStructure() != null) {
            return this.settings!.getMarketStructure()!.getClosesSalePeriod(new Date());
        }
        return null;
    }

    get sundayEvent(): EventStructure | null {
        if (this.settings != null && this.settings.getMarketStructure() != null) {
            let sundayList = this.settings!.getMarketStructure()!.getSundaysAfter(new Date());
            if (sundayList != null && sundayList.length > 0)
                return sundayList[0]
        }
        return null;
    }

    getNameDay(index: number) {
        return this.summary != null && this.summary.getNameDay().length > index ? this.summary.getNameDay()[index] : null;
    }

}

