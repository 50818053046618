import {PosIF} from "@/model/basic/ModelBasic";
import moment from "moment";

export class UtilitiesMath {

    static distanceInKmList(loc1: PosIF[]): number {
        let totalDistanceKm = 0;
        for (let idx = 0; idx < loc1.length; idx++) {
            if (idx > 0)
                totalDistanceKm += UtilitiesMath.distanceInKm(loc1[idx - 1], loc1[idx])
        }
        return totalDistanceKm;
    }

    static distanceInKm(loc1: PosIF, loc2: PosIF): number {
        return UtilitiesMath.distanceKm(loc1.lat, loc1.lon, loc2.lat, loc2.lon);
    }

    static distanceOrDuration(listPoint: PosIF[], index: number, isDistance: boolean, speedInKnots: number) {
        let distanceKm = UtilitiesMath.distanceInKmList(listPoint.slice(0, index + 1));
        if (isDistance)
            return UtilitiesMath.roundOne(UtilitiesMath.convertKmToMiles(distanceKm)) + ' miles';
        else
            return UtilitiesMath.durationIn(distanceKm, speedInKnots);
    }

    static distanceKm(lat1: number, lon1: number, lat2: number, lon2: number) {
        let R = 6371; // km
        let dLat = UtilitiesMath.toRad(lat2 - lat1);
        let dLon = UtilitiesMath.toRad(lon2 - lon1);
        lat1 = UtilitiesMath.toRad(lat1);
        lat2 = UtilitiesMath.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    static toDMS(num: number): string {
        let out = UtilitiesMath.toDegreesMinutesSeconds(num);
        return out[0] + " " + out[1] + "' " + out[2] + "''"
    }

    /**
     * Internal utility to convert from DD.FFFFF to {DD,MM,SS}
     *
     * @param decimalDegree, the coordinate in DD.FFFFFF format
     * @return a double array containing {DD,MM,SS}
     */
    static toDegreesMinutesSeconds(decimalDegree: number): number[] {  // define variables local to this method
        let doubleFraction;            // fraction after decimal
        let doubleSecondsTotal;            // fraction converted to seconds

        // Result
        let dfDegree;
        let dfMinute;
        let dfSecond;

        // Get degrees by chopping off at the decimal
        dfDegree = Math.floor(decimalDegree);
        if (dfDegree < 0)
            dfDegree = dfDegree + 1;

        // Get fraction after the decimal
        doubleFraction = Math.abs(decimalDegree - dfDegree);

        // Convert this fraction to seconds (without minutes)
        doubleSecondsTotal = doubleFraction * 3600;

        // Determine number of whole minutes in the fraction
        dfMinute = Math.floor(doubleSecondsTotal / 60);

        // Put the remainder in seconds
        dfSecond = doubleSecondsTotal - (dfMinute * 60);

        // Fix rounoff errors
        if (Math.round(dfSecond) == 60) {
            dfMinute = dfMinute + 1;
            dfSecond = 0;
        }

        if (Math.round(dfMinute) == 60) {
            if (dfDegree < 0)
                dfDegree = dfDegree - 1;
            else // ( dfDegree => 0 )
                dfDegree = dfDegree + 1;
            dfMinute = 0;
        }
        return [dfDegree, dfMinute, UtilitiesMath.roundOne(dfSecond)];
    }

    static toDegreesMinutesSecondsString(decimalDegree: number): string {
        let numberArray = this.toDegreesMinutesSeconds(decimalDegree);
        return `${numberArray[0]}° ${numberArray[1]}' ${numberArray[2]}''`;
    }


    static roundOne(num: number): number {
        return Math.round(num * 10) / 10;
    }

    static roundTwo(num: number): number {
        return Math.round(num * 100) / 100;
    }

    static roundThree(num: number): number {
        return Math.round(num * 1000) / 1000;
    }

    static roundFour(num: number): number {
        return Math.round(num * 10000) / 10000;
    }

    static durationIn(distanceKm: number, speedKnots: number, format: string = "HH:mm") {
        let minutes = distanceKm * (60 / UtilitiesMath.convertKnotsToKmh(speedKnots));
        //return minutes;
        let duration = moment.duration(minutes, 'minutes');
        if (duration.days() > 1)
            return UtilitiesMath.roundOne(duration.asDays()) + " days";
        return UtilitiesMath.roundOne(duration.asHours()) + " hours";
    }

    static convertKmToMiles(km: number, isRound: boolean = false) {
        let miles = km * 0.539956803;
        return isRound ? UtilitiesMath.roundOne(miles) : miles
    }

    static convertKnotsToKmh(knots: number) {
        return knots * 1.852;
    }

    static convertKmhToKnots(kmh: number) {
        return kmh / 1.852;
    }

    // Converts numeric degrees to radians
    static toRad(value: number) {
        return value * Math.PI / 180;
    }

    /*static analyze(listPosition: Array<PositionStructure>): { speedAverage: number, speedMin: PositionStructure | null, speedMax: PositionStructure | null, distanceKm: number, count: number } {
        let distanceKm = 0;
        let speedMin: PositionStructure | null = null;
        let speedMax: PositionStructure | null = null;
        let speedAverage = 0;
        for (let index = 0; index < listPosition.length; index++) {
            if (index > 0)
                distanceKm += this.distanceInKm(listPosition[index - 1], listPosition[index]);
            speedAverage += listPosition[index].sog || 0;

            //@ts-ignore
            if (listPosition[index].sog != null && (speedMin == null || (listPosition[index].sog < speedMin.sog))) {
                speedMin = listPosition[index];
            }
            //@ts-ignore
            if (listPosition[index].sog != null && (speedMax == null || (listPosition[index].sog > speedMax.sog))) {
                speedMax = listPosition[index];
            }
        }

        speedAverage = speedAverage / listPosition.length;
        return {speedAverage, speedMin, speedMax, distanceKm, count: listPosition.length};
    }*/

}

