
















































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {Language, WeatherURLs} from "@/utilities/UtilitiesApp";
import {mixins} from "vue-class-component";
import {Component, Prop, Watch} from "vue-property-decorator";
import {WeatherStructureOpen} from "@/model/ModelApp";

@Component
export default class ComponentWeather extends mixins(ComponentBasic) {
    selected: string = "";
    LN: string = "[ComponentWeather]::";

    rowWidth: number = 2; /*xs2*/
    countDays: number = 5;

    weatherUrls: { value: string, name: string }[] = WeatherURLs;
    weatherSelected: { value: string, name: string } = WeatherURLs[0];
    payload: PayloadStructure<any> = PayloadStructure.loading(Language.pleaseWait);

    /*small, medium, big accepted*/
    @Prop({default: 'big'})
    mode!: string;

    @Prop({default: true})
    showText!: boolean;

    created() {
        this.calcContent();

    }

    async calcContent() {
        if (this.mode == 'small') {
            this.rowWidth = 6;
            this.countDays = 1
        } else if (this.mode == 'medium') {
            this.rowWidth = 4;
            this.countDays = 3;
        } else { /*big Default*/
            this.rowWidth = 2;
            this.countDays = 5;
        }
        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.Log("Calc content using [" + this.weatherSelected + "]");
        let value: string = this.weatherSelected.value;
        try {
            let weatherData = await RestClientApergia.getCache(null, null, value);
            let structure = new WeatherStructureOpen(weatherData.data);
            this.Log("Weather loaded", structure);
            if (structure.isValid())
                this.payload = PayloadStructure.info(structure);
            else
                this.payload = PayloadStructure.error("Failed Loading Weather");
        } catch (error) {
            this.Log("Failed loaded weather", error);
            this.payload = PayloadStructure.error(Language.error)
        }
    }

    goTo(path: string) {
        if (path != null) {
            this.$router.push({path: path});
        }
    }

    @Watch("weatherSelected")
    onWeatherSelected() {
        this.calcContent();
    }
}

