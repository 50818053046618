
































































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Language} from "@/utilities/UtilitiesApp";
import {EventStructure} from "@/model/ModelApp";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Component({})
export default class extends mixins(ComponentBasic) {
    LN: string = "[PageEvent]::";
    eventId: string | null = null;
    payload: PayloadStructure<EventStructure> = PayloadStructure.loading(Language.pleaseWait);
    event: EventStructure | null = null;

    created() {
        this.calcContent();
    }

    calcContent() {
        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.eventId = this.$route.params.id;
        this.GetEventPromise(this.eventId)
            .then((event) => {
                this.payload = PayloadStructure.info(event);
                this.event = event;
            }).catch((error) => {
                this.payload = PayloadStructure.error(Language.error)
            }
        );
    }

    // eslint-disable-next-line
    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }
}
