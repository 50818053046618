import {EventStructure, CalendarItem} from "@/model/ModelApp";
//@ts-ignore
import * as moment from 'moment-timezone';
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

export const TypePath = {
    Home: {path: '/home'},
    Test: {path: '/test'},
    Content: {path: '/content'},
    Contact: {path: '/contact'},
    Holidays: {path: '/holidays'},
    Market: {path: '/market'},
    Weather: {path: '/weather'},
    Event: {path: '/event'},
    Events: {path: '/events'},
    Archive: {path: '/archive'},
    Stats: {path: '/stats'},
    NameDays: {path: '/namedays'},
    AdminLogin: {path: '/admin'},
    AdminEditContent: {path: '/admin/editor'},
    AdminEditEvents: {path: '/admin/events'},
    AdminEditSettings: {path: '/admin/settings'},
};

export const TypeContent = {
    Main: 'main',
    Phones: 'phones',
    InfoPrices: 'mmm-prices',
    InfoRoutes: 'mmm-routes',
    WhoWeAre: 'whoweare',
    Apps: 'apps'
};
/**
 * Define constants to be used in various function
 * @type {{numbers: string[], days: string[], months: string[], defaultImage: string}}
 */
export const Constants: { [key: string]: string[] } = {
    numbers: ["μηδέν", "μία", "δύο", "τρείς", "τέσσερεις", "πέντε", "έξι", "εφτά", "οχτώ", "εννέα", "δέκα"],
    daysClose: ["Σήμερα", "Αύριο", "Μεθαύριο"],
    defaultImageOther: ["other"] /*256.other.png*/,
    defaultImageSun: ["sun"] /*256.sun.png*/,
    defaultImagePresent: ["present"] /*256.sun.png*/,
};

export const Language: { [key: string]: string } = {
    loadingData: "Φόρτωση απεργιών",
    error: "Σφάλμα Φόρτωσης",
    pleaseWait: "Παρκαλώ περιμένετε...",
    msgAllStrikesInCalendar: "αναλυτικά όλες οι απεργίες καταγράφονται στο ημερολόγιο παρακάτω",
    noStrikes: "Δεν υπάρχουν καταχωρημενες απεργίες",
    noStrikesPleaseInformer: "Δεν υπάρχουν καταχωρημενες απεργίες, αν έχετε αλλη πληροφόρηση παρακαλώ ενημερώστε μας!!"
};

export const WeatherURLs: { value: string, name: string }[] = [
    {value: "wathens", name: "Αθήνα"},
    {value: "wpiraeus", name: "Πειραιάς"},
    {value: "wkifisia", name: "Κηφισία"},
    {value: "wthessaloniki", name: "Θεσσαλονίκη"},
    {value: "wagiaparaskevi", name: "Αγία Παρασκευή"},
    {value: "wmarousi", name: "Μαρούσι"},
    {value: "wkalamata", name: "Καλαμάτα"},
    {value: "wpatra", name: "Πάτρα"}
];

/**
 * Based on event "sub-category"
 * The image will be displayed
 */
export const EventSubTypes2: Map<string, { "image": string[], "text"?: string[] }> = new Map<string, { "image": string[], "text"?: string[] }>(
    [
        ["gsee", {"image": ["gsee"], "text": ["γσεε"]}],
    ]);

export const EventSubTypes: { [key: string]: { "image": string[], "text"?: string[] } } = {
    "gsee": {"image": ["gsee"], "text": ["γσεε"]},
    "adedy": {"image": ["adedy"], "text": ["αδεδυ"]},
    "justice": {"image": ["justice"], "text": ["δικαστ", "νομικ", "δικηγ"]},
    "health": {"image": ["doctor"], "text": ["γιατρ", "νοσοκομ", "ποεοδην", "κλινικ"]},
    "dimos": {"image": ["other_clean2"], "text": ["οτα", "δημοτικοι", "δήμοτικοι", "δήμοι", "δημοι", "δήμου"]},
    "sigentrosi": {"image": ["other"], "text": ["συλλαλητ", "συγκέντρωση", "πορεία"]},
    "school": {"image": ["school"], "text": ["ολμε", "καθηγητ", "δασκαλ"]},
    "finance.bank": {"image": ["bank"], "text": ["τραπεζ", "τράπεζ"]},
    "university": {"image": ["school"], "text": ["εμπ", "μετσόβειο", "πανεπιστημιακ", "εκπα"]},
    "other.stayhome": {"image": ["stayhome"], "text": ["covid-19", "covid19"]},
    "other.staysafe": {"image": ["staysafe"], "text": ["covid-staysafe-19", "covid-staysafe"]},
    "other.tourism": {"image": ["tourism"], "text": ["ξενοδο", "ξενοδό"]},
    "mme": {
        "image": ["mme.3"],
        "text": ["μμε", "δημοσιογρ", "δήμοσιογρ", "εφημεριδ", "star", "mega", "αντεννα", "antenna", "ερτ"]
    },
    "mmm.metro": {"image": ["metro"], "text": ["μετρό", "μετρο"]},
    "mmm.ose": {"image": ["ose"], "text": ["οσε", "προαστιακ"]},
    "mmm.isap": {"image": ["hsap"], "text": ["ησαπ", "ηλεκτρικός"]},
    "mmm.bus": {"image": ["bus"], "text": ["λεωφορεία", "λεωφορεια"]},
    "mmm.trolei": {"image": ["trolei"], "text": ["ηλπαπ", "τρολεϊ", "τρόλεϊ", "τρολει", "τρόλλεϋ"]},
    "mmm.tram": {"image": ["tram"], "text": ["τράμ", "τραμ"]},
    "mmm.taxi": {"image": ["taxi"], "text": ["ταξί", "ταξι"]},
    "mmm.pno": {"image": ["sea.2"], "text": ["πνο", "πλοία", "ναυτεργάτες", "λιμάνι"]},
    "mmm.airplane": {"image": ["air"], "text": ["οσυπα", "πτησεις", "πτήσεις", "αεροδρόμια", "εναέριας", "εναεριας"]},
    "market.sale": {"image": ["market.sale"]},
    "market.basket": {"image": ["market.basket.blue"]},
    "sun": {"image": ["sun"]},
    "nameday": {"image": ["present"]},
    "date": {"image": ["date"]},
    "holiday_xmas": {"image": ["holiday_xmas"]},
    "holiday_party": {"image": ["holiday_party"]},
    "holiday_church": {"image": ["holiday_church"]},
    "holiday_easter": {"image": ["holiday_easter"]},
    "holiday_flag": {"image": ["holiday_flag"]},
    "holiday_kite": {"image": ["holiday_kite"]},
    "holiday_parents": {"image": ["holiday_parents"]},
    "holiday_flower": {"image": ["holiday_flower"]}
};

const image_prefix: string = 'extra/img/';

export class UtilitiesApp extends UtilitiesBasic {

    static staticSettings() {
        return {
            isTopBarCompact: false,
            isShowSideBar: false
        }
    }


    static toImageSubCategoryFromString(categorySubName: string | null, fallBackCategory: string = Constants.defaultImageOther[0]): string {
        if (categorySubName == null || categorySubName.trim() === '')
            return image_prefix + 'events/256.' + fallBackCategory + ".png";
        return image_prefix + 'events/256.' + categorySubName + ".png";
    }

    static toImageSubCategoryFromEvent(event: EventStructure, fallBackCategory: string = Constants.defaultImageOther[0]): string {
        try {
            if (event == null)
                return UtilitiesApp.toImageSubCategoryFromString(null, fallBackCategory);

            //@ts-ignore
            let image = event.getCategorySub() != null ? EventSubTypes[event.getCategorySub()]["image"][0] : null;
            return UtilitiesApp.toImageSubCategoryFromString(image, fallBackCategory);
        } catch (error) {
            console.log(error);
            return UtilitiesApp.toImageSubCategoryFromString(null, fallBackCategory);
        }
    }

    // Return strike categories
    // We could use this from server
    static getCalendarSearchCategories(): { text: string, value: string | null }[] {
        return [
            {text: 'Όλες', value: null},
            {text: 'Μέσα Μαζικής Μεταφοράς', value: 'strikeTransportation'},
            {text: 'Εκπαίδευση', value: 'strikeEducation'},
            {text: 'Πορείες/Συγκεντρώσεις', value: 'strikeGathering'},
            {text: 'Άλλα', value: 'strikeVarious'},
        ]
    }

    // We could use this from server
    static getCalendarViewCategories(): CalendarItem[] {
        return [
            new CalendarItem("Συγκοινωνίες", image_prefix + "calendar/mmm.60.png", "apergia.gr_bp97go9er2e3r89qvmotivqkg8%40group.calendar.google.com", "#0D7813", true, true),
            new CalendarItem("Διάφορα", image_prefix + "calendar/various.60.png", "apergia.gr_86gj20os85vllej3u5s1o17ge4%40group.calendar.google.com", "#2952A3", true, true),
            new CalendarItem("Εκπαίδευση", image_prefix + "calendar/education.60.png", "apergia.gr_ubk3rp6ef05t7pmgu9j5k2e0f0%40group.calendar.google.com", "#A32929", true, true),
            new CalendarItem("Πορείες", image_prefix + "calendar/protest.60.png", "apergia.gr_1hhivpgkguk9ubup4dk5ucchqc%40group.calendar.google.com", "#865A5A", true, true),
            new CalendarItem("Γιορτές", image_prefix + "calendar/giortes.60.png", "apergia.gr_evs3g0c0gfdaci0p5lke8uff2k%40group.calendar.google.com", "#AB8B00", true, true),
            new CalendarItem("Αργίες", image_prefix + "calendar/holidays.60.png", "apergia.gr_r205ipb20ccsppni0lbsg1m17s%40group.calendar.google.com", "#AB8B00", true, true),
            new CalendarItem("Καταστήματα", image_prefix + "calendar/giortes.60.png", "apergia.gr_jsqivedglj0h7r36as5ku2rlpc%40group.calendar.google.com", "#1B887A", true, false)
        ]
    }

    /*http://lyk-peir-anavr.att.sch.gr/Lessons/04NATURALSCI/Astronomy/General/04_Easter/Theory.htm*/
    static getDateEasterSunday(year: number, plusDays: number): Date {
        plusDays = plusDays || 0;
        let num = ((19 * (year % 19) + 16) % 30) + (((2 * (year % 4)) + (4 * (year % 7)) + 6 * ((19 * (year % 19) + 16) % 30)) % 7) + 3;
        let date;
        if (num < 31) {
            date = new Date(year, 3, num + plusDays)
        } else {
            date = new Date(year, 4, num - 30 + plusDays);
        }
        return date;
    }

    /*Don't forget month jan is 0*/
    static getHolidayOf(year: number): EventStructure[] {
        let event = [
            EventStructure.eventUrl('Πρωτοχρονιά', "holiday_party", new Date(year, 0, 1), ''),
            EventStructure.eventUrl('Θεοφάνεια', "holiday_church", new Date(year, 0, 6), ''),
            EventStructure.eventUrl('Καθαρά Δευτέρα', "holiday_kite", UtilitiesApp.getDateEasterSunday(year, -48), ''),
            EventStructure.eventUrl('Επανάσταση 1821', "holiday_flag", new Date(year, 2, 25), ''),
            EventStructure.eventUrl('Εργατική Πρωτομαγιά', "holiday_flower", new Date(year, 4, 1), ''),
            EventStructure.eventUrl('Μεγάλη Παρασκευή', "holiday_easter", UtilitiesApp.getDateEasterSunday(year, -2), ''),
            EventStructure.eventUrl('Δευτέρα του Πάσχα', "holiday_easter", UtilitiesApp.getDateEasterSunday(year, 1), ''),
            EventStructure.eventUrl('Αγίου Πνεύματος', "holiday_church", UtilitiesApp.getDateEasterSunday(year, 50), ''),
            EventStructure.eventUrl('Κοίμηση της Θεοτόκου', "holiday_church", new Date(year, 7, 15), ''),
            EventStructure.eventUrl('Επέτειος του Όχι', "holiday_flag", new Date(year, 9, 28), ''),
            EventStructure.eventUrl('Χριστούγεννα', "holiday_xmas", new Date(year, 11, 25), ''),
            EventStructure.eventUrl('2ή ημέρα Χριστουγέννων', "holiday_xmas", new Date(year, 11, 26), '')
        ];
        event.sort((event1, event2) => {
            //@ts-ignore
            return event1.dateFrom > event2.dateFrom ? 1 : -1
        });
        return event;
    }

    static getHolidayClosest(date: Date = new Date()): EventStructure {
        let events = this.getHolidayOf(date.getFullYear());
        let result = null;
        for (let event of events) {
            //@ts-ignore
            if (event.getDateFrom() != null && event.getDateFrom().getTime() >= date.getTime()) {
                result = event;
                break;
            }
        }
        if (result == null) {
            result = this.getHolidayOf(date.getFullYear() + 1)[0]
        }
        return result;
    }

    static toStringNoAccent(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    static toStringEvent(listEvent: EventStructure[] | null = null): string {
        if (listEvent == null || listEvent.length === 0) {
            return Language.noStrikes;
        } else {
            if (listEvent.length === 1) {
                return listEvent[0].title
            } else {
                return this.toStringNumber(listEvent.length) + " καταχωρημένες ενημερώσεις";
            }
        }
    }

    static toURLEvent(event: EventStructure, isFull: boolean = false) {
        let subUrl = '';
        let id = event != null ? event.idSource : null;
        if (id != null)
            subUrl = TypePath.Event.path + "/" + id;
        else
            subUrl = TypePath.Home.path;

        if (isFull)
            subUrl = '/#' + subUrl;
        return subUrl;
    }

    static toURLEvents(dateFrom: Date = new Date(), plusDays: number = 12, textSearch: string = ''): string {
        plusDays = plusDays || 12;
        textSearch = textSearch || '';
        return TypePath.Events.path + "/" + this.toURLStringFromDate(dateFrom) + "/" + plusDays + "/" + textSearch;
    }

    /**
     * Create new event with summary (example: using only the current date)
     */
    static toSummaryEvent(listEvents: EventStructure[], date: Date = new Date(), prefix: string | null = null): EventStructure {
        listEvents = listEvents || [];
        let result = new EventStructure();
        result.title = (prefix != null ? prefix : UtilDate.toStringDiffInDays(new Date(), date)) + ", " +  UtilitiesApp.toStringEvent(listEvents);
        result.dateFrom = date;
        result.url = listEvents.length == 1 ? UtilitiesApp.toURLEvent(listEvents[0]) : this.toURLEvents(new Date());

        //image
        if (listEvents.length === 0)
            result.icon =  UtilitiesApp.toImageSubCategoryFromString(Constants.defaultImageSun[0]);
        else if (listEvents.length === 1) {
            result.category = listEvents[0].getCategory();
            result.icon =  UtilitiesApp.toImageSubCategoryFromEvent(listEvents[0]);
            //force date to be the input date in order to display it
            result.dateFrom = date;
            //result.dateFrom = listEvents[0].dateFrom;
        } else if (listEvents.length >= 1) {
            let subCategoryCommon = listEvents[0].categorySub;
            listEvents.forEach(i => {
                if (i.categorySub != subCategoryCommon)
                    subCategoryCommon = null;
            });
            result.icon = subCategoryCommon != null ?  UtilitiesApp.toImageSubCategoryFromEvent(listEvents[0]) :  UtilitiesApp.toImageSubCategoryFromString(Constants.defaultImageOther[0]);
        }
        return result;
    }

    static getHostnameFromURL(url: string = "") {
        if (url == null)
            return "";
        let hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        url = url.toLowerCase();
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname.replace("www.", "");
    }

    static Log(msg: string, obj: any | null = null) {
        // eslint-disable-next-line
        console.log(msg);
        if (obj != null) {
            // eslint-disable-next-line
            console.log(obj);
        }
    }

    static toStringNumber(count: number) {
        return (count < Constants.numbers.length ? Constants.numbers[count] : count)
    }

    static toEventDatePretty(event: EventStructure, withYear: boolean = false, withEnd: boolean = false, isShowDays: boolean = true): string {
        let dateFrom: Date | null = event.dateFrom;
        if (dateFrom == null)
            return "no date from";
        //@ts-ignore
        let dateStart = moment(dateFrom).tz('Europe/Athens').format(withYear ? 'dddd DD MMMM YYYY' : 'dddd DD MMMM');
        let days = UtilDate.getDiffInDays(dateFrom, event.dateTo != null ? event.dateTo : dateFrom);
        if (withEnd) {
            if (event.dateTo == null)
                return "no date to";
            //@ts-ignore
            let dateEnd = moment(event.dateTo).tz('Europe/Athens').format(withYear ? 'dddd DD MMMM YYYY' : 'dddd DD MMMM');
            return dateStart + " έως " + dateEnd + ((days > 1 && isShowDays) ? (" (" + UtilitiesApp.toStringNumber(days) + " ημέρες)") : "");
        } else {
            return dateStart + ((days > 1 && isShowDays) ? (" (" + UtilitiesApp.toStringNumber(days) + " ημέρες)") : "");
        }
    }

    static toDatePlusDays(dateFrom: Date = new Date(), plusDays = 0) {
        let dateNew = new Date();
        dateNew.setTime(dateFrom.getTime());
        dateNew.setDate(dateFrom.getDate() + plusDays);
        return dateNew;
    }

    static getTypeFromTitle(text: string | EventStructure | null): string | null {
        try {
            if (text instanceof EventStructure) {
                text = text.title;
            }
            text = text != null ? text.toLowerCase() : '';
            for (let subType in EventSubTypes) {
                //@ts-ignore
                let textTypes = EventSubTypes[subType]["text"];
                if (textTypes) {
                    for (let textType of textTypes) {
                        if (text.indexOf(textType) !== -1) {
                            return subType;
                        }
                    }
                } else {
                    // no types (for example xmas)
                }
            }
        } catch (error) {
        }
        return null;
    }

    static toURLStringFromDate(date: Date): string {
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format('YYYY.MM.DD');
    }

    static toURLDateFromString(text: string, fallback: Date | null = null): Date | null {
        try {
            if (text != null) {
                //@ts-ignore
                let date = moment(text, 'YYYY.MM.DD').tz('Europe/Athens').toDate();
                if (date instanceof Date)
                    date.setHours(0, 0, 0, 0);
                return date;
            }
        } catch (error) {
        }
        return fallback;
    }

    static toWeatherClassFromOpen(code: string) {
        return "wi-owm-" + code;
    }

    static toWeatherColorFromOpen(code: number): string {
        if (code == 800)
            return "rgb(207, 170, 0)";//Colors.orange;
        if (code > 800) //new Color(0xFFD3D300);//Colors.amber;
            return "#1976d2"
        if (code >= 500 && code < 700)
            return "grey";
        return "#1976d2";
    }
}

export class UtilDate {

    /**
     * σε πέντε ημέρες
     * πριν μια ημέρα
     * @param dateFrom date
     * @param dateTo date
     * @returns {string}
     */
    static toStringDiffInDays(dateFrom: Date, dateTo = new Date()): string {
        return this.toStringDays(this.getDiffInDays(dateFrom, dateTo));
    }

    static toStringTwoNumber(num: number): string {
        let numString = num.toString();
        if (numString.length <= 1) {
            return "0" + numString;
        }
        return numString;
    }

    static toStringDays(days: number): string {
        if (days >= 0 && days < Constants.daysClose.length)
            return Constants.daysClose[days];
        else {
            let prefix = days < 0 ? "Πριν " : "Σε ";
            days = Math.abs(days);

            return prefix + UtilitiesApp.toStringNumber(days) + (days === 1 ? " ημέρα" : " ημέρες");
        }
    }

    static getDiffInDays(dateFrom: Date, dateTo = new Date()): number {
        let _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        let utcFrom = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        let utcTo = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utcTo - utcFrom) / _MS_PER_DAY);
    }

    static initMoment() {
        moment.tz.add("Europe/Athens|AMT EET EEST CEST CET|-1y.Q -20 -30 -20 -10|012123434121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2a61x.Q CNbx.Q mn0 kU10 9b0 3Es0 Xa0 1fb0 1dd0 k3X0 Nz0 SCp0 1vc0 SO0 1cM0 1a00 1ao0 1fc0 1a10 1fG0 1cg0 1dX0 1bX0 1cQ0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|35e5");
    }

    static toStringDatePretty(date: Date | null, withYear: boolean = false, dateFormat:string = 'dddd, DD MMMM'): string {
        if (date == null)
            return "n/a";
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format(withYear ? 'dddd DD MMMM YYYY' : 'dddd, DD MMMM');
    }

    static toStringDatePrettyNum(date: Date, withYear = false): string {
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format(withYear ? 'dddd DD/MM/YYYY' : 'dddd, DD/MM');
    }

    static toStringTime(date: Date, withDate: boolean = false): string {
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format(withDate ? 'dddd DD/MM/YYYY HH:mm' : 'HH:mm');
    }

    static toStringCustom(date: Date, fmt: string): string {
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format(fmt ? fmt : 'dddd DD/MM/YYYY');
    }

    static toEventDateTime(event: EventStructure, format = 'dddd DD MMM, HH:mm'): string {
        //@ts-ignore
        return this.toDateTime(event.dateFrom, format);
    }

    static toDateTime(date: Date | null, format = 'dddd DD MMM, HH:mm'): string {
        //@ts-ignore
        return moment(date).tz('Europe/Athens').format(format);
    }
}

/**

export class UtilConvert {

    static staticSettings() {
        return {
            isTopBarCompact: false,
            isShowSideBar: false
        }
    }

    static isMobile(): boolean {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }

    static toImageSubCategoryFromString(categorySubName: string | null, fallBackCategory: string = Constants.defaultImageOther[0]): string {
        if (categorySubName == null || categorySubName.trim() === '')
            return image_prefix + 'events/256.' + fallBackCategory + ".png";
        return image_prefix + 'events/256.' + categorySubName + ".png";
    }

    static toImageSubCategoryFromEvent(event: EventStructure, fallBackCategory: string = Constants.defaultImageOther[0]): string {
        try {
            if (event == null)
                return UtilConvert.toImageSubCategoryFromString(null, fallBackCategory);

            //@ts-ignore
            let image = event.getCategorySub() != null ? EventSubTypes[event.getCategorySub()]["image"][0] : null;
            return UtilConvert.toImageSubCategoryFromString(image, fallBackCategory);
        } catch (error) {
            console.log(error);
            return UtilConvert.toImageSubCategoryFromString(null, fallBackCategory);
        }
    }

    static getTypeFromTitle(text: string | EventStructure | null): string | null {
        try {
            if (text instanceof EventStructure) {
                text = text.title;
            }
            text = text != null ? text.toLowerCase() : '';
            for (let subType in EventSubTypes) {
                //@ts-ignore
                let textTypes = EventSubTypes[subType]["text"];
                if (textTypes) {
                    for (let textType of textTypes) {
                        if (text.indexOf(textType) !== -1) {
                            return subType;
                        }
                    }
                } else {
                    // no types (for example xmas)
                }
            }
        } catch (error) {
        }
        return null;
    }

    // Return strike categories
    // We could use this from server
    static getCalendarSearchCategories(): { text: string, value: string | null }[] {
        return [
            {text: 'Όλες', value: null},
            {text: 'Μέσα Μαζικής Μεταφοράς', value: 'strikeTransportation'},
            {text: 'Εκπαίδευση', value: 'strikeEducation'},
            {text: 'Πορείες/Συγκεντρώσεις', value: 'strikeGathering'},
            {text: 'Άλλα', value: 'strikeVarious'},
        ]
    }

    // We could use this from server
    static getCalendarViewCategories(): CalendarItem[] {
        return [
            new CalendarItem("Συγκοινωνίες", image_prefix + "calendar/mmm.60.png", "apergia.gr_bp97go9er2e3r89qvmotivqkg8%40group.calendar.google.com", "#0D7813", true, true),
            new CalendarItem("Διάφορα", image_prefix + "calendar/various.60.png", "apergia.gr_86gj20os85vllej3u5s1o17ge4%40group.calendar.google.com", "#2952A3", true, true),
            new CalendarItem("Εκπαίδευση", image_prefix + "calendar/education.60.png", "apergia.gr_ubk3rp6ef05t7pmgu9j5k2e0f0%40group.calendar.google.com", "#A32929", true, true),
            new CalendarItem("Πορείες", image_prefix + "calendar/protest.60.png", "apergia.gr_1hhivpgkguk9ubup4dk5ucchqc%40group.calendar.google.com", "#865A5A", true, true),
            new CalendarItem("Γιορτές", image_prefix + "calendar/giortes.60.png", "apergia.gr_evs3g0c0gfdaci0p5lke8uff2k%40group.calendar.google.com", "#AB8B00", true, true),
            new CalendarItem("Αργίες", image_prefix + "calendar/holidays.60.png", "apergia.gr_r205ipb20ccsppni0lbsg1m17s%40group.calendar.google.com", "#AB8B00", true, true),
            new CalendarItem("Καταστήματα", image_prefix + "calendar/giortes.60.png", "apergia.gr_jsqivedglj0h7r36as5ku2rlpc%40group.calendar.google.com", "#1B887A", true, false)
        ]
    }

    static getStringToColor(str:string):string {
        let hash:number = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    static getGoogleURL(name: string): string {
        return "https://www.google.com/search?q=" + name;
    }

    static getCurrentLink(): string {
        try {
            return window.location.href;
        } catch (error) {
            return '';
        }

    }
}
*/
