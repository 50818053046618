/**
 * Event Structure (calendar OR news)
 *
 Strike
 {
       "idSource": "0c83rk8eqd5gqv8os695dve4v7",
       "title": "Δάσκαλοι & Καθηγητές (ΔΟΕ & ΟΛΜΕ)  3ωρη στάση εργασίας",
       "description": "<div>\n <br />Σε τρίωση στάση εργασίας προχωρούν την Παρασκευή 9 Νοεμβρίου η ΔΟΕ και η ΟΛΜΕ, που δίνουν ραντεβού στις 13:00 έξω από το υπουργείο Παιδείας.\n <br />\n <br />Οι καθηγητές κάνουν λόγο για &laquo;πολιτικές λιτότητας και περιορισμού δαπανών&raquo; που &laquo;άφησαν το στίγμα τους στη ζωή εργαζομένων και συνταξιούχων και στην υποβάθμιση του δημόσιου σχολείου στα χρόνια των μνημονίων&raquo;.\n <br />\n <br />&laquo;Οι ελάχιστες συνταξιοδοτήσεις των τελευταίων ετών, οι μηδενικοί διορισμοί στην εκπαίδευση, η εργασιακή ανασφάλεια των αναπληρωτών εκπαιδευτικών, οι μεγάλες δυσκολίες που αντιμετωπίζουν στη στέγασή τους και η άνιση αντιμετώπιση σε ότι αφορά τα δικαιώματά τους (άδειες κύησης-ανατροφής κ.α.) αποτελούν κορυφαία ζητήματα που χρόνο με τον χρόνο εντείνονται&raquo; λένε από την πλευρά τους οι δάσκαλοι.\n <br />\n <br /></div>",
       "category": "strikeEducation",
       "categorySub": "school",
       "url": "https://www.newsbeast.gr/greece/arthro/4164082/se-3ori-stasi-ergasias-tin-paraskeyi-doe-kai-olme",
       "sourceUrl": "https://www.newsbeast.gr",
       "dateFrom": "Nov 9, 2018 12:00:00 AM",
       "dateFromMs": 1541714400000,
       "dateTo": "Nov 10, 2018 12:00:00 AM",
       "dateToMs": 1541800800000,
       "dateCreated": "Nov 5, 2018 4:17:53 PM",
       "dateCreatedMs": 1541427473000,
       "id": 6699
   }

 Nameday
 {
        "title": "Γιορτή:Θεοδόσης,Θεοδοσία",
        "category": "nameDays",
        "categorySub": "nameday",
        "dateFrom": "May 29, 1900 12:00:00 AM",
        "dateFromMs": -2196207292000,
        "dateToMs": 0,
        "dateCreated": "Nov 18, 2018 7:23:54 PM",
        "dateCreatedMs": 1542561834874,
        "id": 8342
 }
 *
 */
//@ts-ignore
import {UtilitiesApp, UtilDate} from "@/utilities/UtilitiesApp";

export class EventStructure {

  id: number = -1;
  idSource: string = '';
  title: string = '';
  category: string | null = null;
  categorySub: string | null = null;
  url: string | null = null;
  icon: string | null = null;

  dateFromMs: number | null = null;
  dateToMs: number | null = null;
  dateCreatedMs: number | null = null;

  dateFrom: Date | null = null;
  dateTo: Date | null = null;
  dateCreated: Date | null = null;

  /*post-generated (used in ui)*/
  daysFromNow :number | undefined;
  daysFromYearNext :number | undefined;

  constructor(jsonObject: any | null = null) {
    if (jsonObject != null)
      Object.assign(this, jsonObject);
    this.dateFrom = (this.dateFromMs != null && this.dateFromMs !== 0) ? new Date(this.dateFromMs) : null;
    this.dateTo = (this.dateToMs != null && this.dateToMs !== 0) ? new Date(this.dateToMs) : null;
    this.dateCreated = (this.dateCreatedMs != null && this.dateCreatedMs !== 0) ? new Date(this.dateCreatedMs) : null;
    //try to find category based on title
    if (this.getCategorySub() == null || "other" === this.getCategorySub()) {
      this.setCategorySub(UtilitiesApp.getTypeFromTitle(this));
    }
  }

  setYearToDateFrom(year: number | null = null) {
    if (year == null) {
      year = new Date().getFullYear()
    }
    if (this.dateFrom != null) {
      this.dateFrom.setFullYear(year);
      this.dateFrom.setHours(0, 0, 0, 0);
      this.dateFromMs = this.dateFrom.getTime();
    }
    return this;
  }

  static event(id: number, title: string | null, categorySub: string | null) {
    let event = new EventStructure();
    event.id = id;
    event.title = title || '';
    event.categorySub = categorySub;
    return event;
  }

  static eventUrl(title: string | null, categorySub: string | null, dateFrom: Date | null, url: string | null) {
    let event = EventStructure.event(-1, title, categorySub);
    event.dateFrom = dateFrom;
    if (event.dateFrom != null)
      event.dateFromMs = event.dateFrom.getTime();
    event.url = url;
    return event;
  }

  setDates(dateFrom: Date | null, dateTo: Date | null) {
    this.dateFrom = dateFrom;
    this.dateFromMs = dateFrom != null && (dateFrom instanceof Date) ? dateFrom.getTime() : null;
    this.dateTo = dateTo;
    this.dateToMs = dateTo != null && (dateTo instanceof Date) ? dateTo.getTime() : null;
    return this;
  }

  getCategory() {
    return this.category;
  }

  getCategorySub() {
    return this.categorySub;
  }

  setCategorySub(cat: string | null) {
    this.categorySub = cat;
  }

  getDateFrom(): Date | null {
    return this.dateFrom;
  }

  getDateTo(): Date | null {
    return this.dateTo;
  }

  getDaysCount(): number {
    if (this.dateFrom != null && this.dateTo != null)
      return UtilDate.getDiffInDays(this.dateFrom, this.dateTo);
    return 1;
  }

  getId(): number {
    return this.id
  }

  toString() {
    return this.title + "@" + this.dateFrom;
  }
}

/**
 * In Calendar, represent a 'calendar' category
 */
export class CalendarItem {

  id: string = '';
  name: string | null = null;
  icon: string | null = null;
  isChecked: boolean | null = null;
  isShowInUi: boolean | null = null;
  color: string | null = null;
  description: string | null = null;
  link: string | null = null;
  // Not used
  dateFrom: Date | null = null;
  dateTo: Date | null = null;

  constructor(name: string | null, icon: string | null, id: string, color: string | null, isChecked: boolean | null, isShowInUi: boolean | null) {
    //console.log("Structure, Constructor of CalendarItem (" + name + ")");
    this.setData(name, icon, id, color, isChecked, isShowInUi, null, null, null, null);
  }

  setData(name: string | null, icon: string | null, id: string, color: string | null, isChecked: boolean | null, isShowInUi: boolean | null, description: string | null, link: string | null, dateFrom: Date | null, dateTo: Date | null) {
    this.name = name;
    this.icon = icon;
    this.id = id;
    this.isChecked = isChecked;
    this.isShowInUi = isShowInUi;
    this.color = color;
    this.description = description;
    this.link = link;
    // Not used
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    return this;
  }
}

/**
 * Just a list of Events (and the total count) if not all events published
 */
export class EventsWrapperStructure {
  listEvents: Array<EventStructure> = new Array<EventStructure>();

  constructor(jsonObject: any | null = null) {
    if (jsonObject != null)
      Object.assign(this, jsonObject);

    if (jsonObject != null) {
      this.listEvents = SummaryStructure.toEventArray(jsonObject.listEvents);
    }
  }
}

/**
 * Group of Events
 */
export class SummaryStructure {
  dateCreatedMs: Date | null = null;
  today: Array<EventStructure> = new Array<EventStructure>();
  tomorrow: Array<EventStructure> = new Array<EventStructure>();
  strikes: Array<EventStructure> = new Array<EventStructure>();
  nameDay: Array<EventStructure> = new Array<EventStructure>();
  holiday: EventStructure | null = null;

  constructor(data: any | null = null) {
    if (data != null) {
      this.dateCreatedMs = new Date(data.dateCreatedMs);
      this.today = SummaryStructure.toEventArray(data.today);
      this.tomorrow = SummaryStructure.toEventArray(data.tomorrow);
      this.strikes = SummaryStructure.toEventArray(data.listEvents);
      this.nameDay = SummaryStructure.toEventArray(data.listNameDays);
      this.holiday = new EventStructure(data.holiday);
    }
  }

  getStrikeToday(): Array<EventStructure> {
    return this.today;
  }

  getStrikeTomorrow(): Array<EventStructure> {
    return this.tomorrow;
  }

  getStrikeComplete(): Array<EventStructure> {
    return this.strikes;
  }

  // Date From ------Date------- Date To
  getStrikesAfter(date: Date | null): Array<EventStructure> {
    if (date == null)
      return this.strikes;
    //@ts-ignore
    return this.strikes.filter(e => /*(e.getDateFrom() != null && e.getDateFrom() <= date) && */(e.getDateTo() != null && e.getDateTo() >= date  ));
  }

  getNameDay(): Array<EventStructure> {
    return this.nameDay;
  }

  static toEventArray(arr: Array<any>, isSorted: boolean = true): Array<EventStructure> {
    if (arr) {
      let result = Array.from(arr, e => new EventStructure(e));
      if (isSorted)
      //@ts-ignore
        result = result.sort((a, b) => (a.getDateFrom() != null && b.getDateFrom() != null && a.getDateFrom() > b.getDateFrom()) ? 1 : -1);
      return result;
    }
    return new Array<EventStructure>();
  }
}

export class StatsStructure {
  //@ts-ignore
  map: Map<string, [Date, number][]> = new Map();

  constructor(data: any | null) {
    if (data != null) {
      let map = new Map();
      for (let key in data) {
        //@ts-ignore
        let arrayOfDateCount = Array.from(data[key], e => [new Date(e.a), e.b]);
        map.set(key, arrayOfDateCount);
      }
      this.map = map;
    } else {
      this.map = new Map();
    }
  }

  getSeriesOf(key: string): [Date, number][] {
    return this.map.get(key) || [];
  }

  getSeriesMap() {
    return this.map;
  }

  getSeriesKeys() {
    return Array.from(this.map.keys());
  }

  getSeriesDefault() {
    let keys = this.getSeriesKeys();
    if (keys.length > 0)
      return this.getSeriesOf(keys[0]);
    return [];
  }

  getTotalCount() {
    let count = 0;
    for (let key of this.getSeriesKeys()) {
      count += this.getSeriesOf(key).reduce((total, currentValue) => total + currentValue[1], 0);
      /*for (let item of this.getSeriesOf(key)) {
          count = count + item[1];
      }*/
    }
    return count;
  }

}

/**
 * Settings contain also the content-map
 */
/**
 "config": {
        "configMarket": {
            "daily": {
                "1": "Κυριακή κλειστά καταστήματα",
                "2": "Δευτέρα ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-21:00",
                "3": "Τρίτη ανοιχτά καταστήματα 09:00-15:30 & 17:30-21:00, πολυκαταστήματα 10:00-21:00",
                "4": "Τετάρτη ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-21:00",
                "5": "Πέμπτη ανοιχτά καταστήματα 09:00-15:30 & 17:30-21:00, πολυκαταστήματα 10:00-21:00",
                "6": "Παρασκευή ανοιχτά καταστήματα 09:00-15:30 & 17:30-21:00, πολυκαταστήματα 10:00-21:00",
                "7": "Σάββατο ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-18:00",
                "image": "",
                "link": ""
            },
            "periods": [
                {
                    "message": "Χειμερινή Περίοδος Εκπτώσεων",
                    "from": "2014.01.13",
                    "to": "2014.02.28",
                    "link": ""
                },
                {
                    "message": "Περίοδος Εκπτώσεων",
                    "from": "2014.05.01",
                    "to": "2014.05.10",
                    "link": ""
                },
                {
                    "message": "Καλοκαιρινές Εκπτώσεις ",
                    "from": "2014.07.14",
                    "to": "2014.08.31",
                    "link": ""
                },
                {
                    "message": "10ήμερο προσφορών",
                    "from": "2014.11.01",
                    "to": "2014.11.10",
                    "link": ""
                }
            ],
            "openSundays": [
                "2014.01.19"
            ]
        },
        "listEventCategories": [
            {
                "name": "main",
                "nameUi": "main",
                "idCalendar": "apergia.gr_i56ueqcpl904vpqsvatl45giv8%40group.calendar.google.com",
                "isStrike": false
            },
            {
                "name": "shops",
                "nameUi": "Καταστήματα",
                "idCalendar": "apergia.gr_jsqivedglj0h7r36as5ku2rlpc@group.calendar.google.com",
                "isStrike": false
            },
            {
                "name": "nameDays",
                "nameUi": "Γιορτές",
                "idCalendar": "apergia.gr_evs3g0c0gfdaci0p5lke8uff2k@group.calendar.google.com",
                "isStrike": false
            },
            {
                "name": "holiday",
                "nameUi": "Αργίες",
                "idCalendar": "apergia.gr_r205ipb20ccsppni0lbsg1m17s%40group.calendar.google.com",
                "isStrike": false
            },
            {
                "name": "strikeVarious",
                "nameUi": "Διάφορα",
                "idCalendar": "apergia.gr_86gj20os85vllej3u5s1o17ge4@group.calendar.google.com",
                "isStrike": true
            },
            {
                "name": "strikeEducation",
                "nameUi": "Εκπαίδευση",
                "idCalendar": "apergia.gr_ubk3rp6ef05t7pmgu9j5k2e0f0@group.calendar.google.com",
                "isStrike": true
            },
            {
                "name": "strikeGathering",
                "nameUi": "Πορείες",
                "idCalendar": "apergia.gr_1hhivpgkguk9ubup4dk5ucchqc@group.calendar.google.com",
                "isStrike": true
            },
            {
                "name": "strikeTransportation",
                "nameUi": "Συγκοινωνίες",
                "idCalendar": "apergia.gr_bp97go9er2e3r89qvmotivqkg8@group.calendar.google.com",
                "isStrike": true
            },
            {
                "name": "DEBUG",
                "nameUi": "DEBUG",
                "idCalendar": "apergia.gr_ubu46bm72our932b5k40j3ka6s@group.calendar.google.com",
                "isStrike": false
            }
        ],
        "calendarHeightPx": "1200",
        "calendarBaseURL": "http://www.google.com/calendar/embed?showTitle=0&showNav=0&showDate=1&showPrint=1&showTabs=1&showCalendars=0&showTz=0&mode=MONTH&wkst=2&bgcolor=%23FFFFFF&hl=en-GB&height=",
        "id": -1
    },
 "content": [
 {
     "type": "whoweare",
     "properties": {
         "show_common_bar": "true"
     },
     "content": "\n<v-layout row wrap>\n    \n      <v-flex xs12>\n        <v-card>\n            \n             <v-card-actions>\n          \n          <v-spacer></v-spacer>\n          <v-btn icon href=\"\\\">\n            <v-icon>home</v-icon>\n          </v-btn>\n        </v-card-actions>\n        \n            <v-card-title primary-title>\n          <div>\n            <h3 class=\"headline mb-0\"><v-icon color='red'>contacts</v-icon>  Ποιοι Είμαστε</h3>\n            <br/>\n            <div style=\"font-size:20px;padding:20px;text-align:left\"> Μια παρέα, εργαζόμενοι και φοιτητές, μετακινείται -όπως όλοι οι Αθηναίοι- με τα μέσα μαζικής μεταφοράς. \n             Οι συνεχείς απεργίες και στάσεις εργασίας στα μέσα τους τροποποιεί διαρκώς το πρόγραμμα και τους ταλαιπωρεί αφόρητα. \n             Με αφορμή, λοιπόν την περιορισμένη ενημέρωση από τα μέσα μαζικής μεταφοράς για το ποιος, πώς αλλά κυρίως, \n             πότε απεργεί, αποφασίζει να αναλάβει την αναζήτηση των επερχόμενων απεργιών και την ενημέρωση όλων των ενδιαφερομένων.  </div>\n          </div>\n        </v-card-title>\n         \n         \n         \n        </v-card>\n        <br/>\n        <v-card>\n            <ad-component type='bar'></ad-component>\n        </v-card>\n    </v-flex>\n    \n\n    \n    \n  \n\n</v-layout>",
     "id": -1
 }

 */
export class SettingsStructure {
  //members json
  configMarket: any | null = null;
  //members Converter
  //mapContent: Map<string, { content: string, properties: any }> = new Map();
  mapContent: Map<string, ContentStructure> = new Map();
  configMarketStructure: MarketStructure | null = null;

  constructor(jsonObject: any | null = null) {
    Object.assign(this, jsonObject);
    try {
      this.configMarketStructure = new MarketStructure(this.configMarket);
    } catch (er) {
      UtilitiesApp.Log("Model", "Error on converting settings " + er);
      this.configMarketStructure = null;
    }
  }

  toString(): string {
    let result = "";
    result += "\r\n[config market:" + (this.configMarketStructure != null ? 'true' : 'false') + "]";
    result += "\n[map content:" + (this.mapContent != null ? this.mapContent.size : 0) + "]";
    return result;
  }

  isShowSideBar() {
    return false;
  }

  isTopBarCompact() {
    return false;
  }

  getMarketStructure() {
    return this.configMarketStructure;
  }

  getContentMap() {
    return this.mapContent;
  }

  /*
  * Special case where config AND content are in the same JSON structure
  **/
  static createSettingsFromJson(jsonObject: any):SettingsStructure {
    let structure = new SettingsStructure(jsonObject.config);
    structure.publishContentMap(jsonObject.content);
    return structure;
  }

  publishContentMap(jsonContent: any):Map<string,ContentStructure> {
    let mapContent = new Map();
    for (let item of jsonContent)
      mapContent.set(item.type, new ContentStructure(item));
    this.mapContent = mapContent;
    return this.mapContent;
  }


}

/**
 * "configMarket": {
      "daily": {
        "1": "Κυριακή κλειστά καταστήματα",
        "image": "",
        "2": "Δευτέρα ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-21:00",
        "3": "Τρίτη ανοιχτά καταστήματα 09:00-15:30 \u0026 17:30-21:00, πολυκαταστήματα 10:00-21:00",
        "4": "Τετάρτη ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-21:00",
        "5": "Πέμπτη ανοιχτά καταστήματα 09:00-15:30 \u0026 17:30-21:00, πολυκαταστήματα 10:00-21:00",
        "6": "Παρασκευή ανοιχτά καταστήματα 09:00-15:30 \u0026 17:30-21:00, πολυκαταστήματα 10:00-21:00",
        "7": "Σάββατο ανοιχτά καταστήματα 09:00-15:30, πολυκαταστήματα 10:00-18:00",
        "link": ""
      },
      "periods": [
        {
          "message": "Χειμερινή Περίοδος Εκπτώσεων",
          "from": "2019.01.14",
          "to": "2019.02.28",
          "link": ""
        },
        {
          "message": "Περίοδος Εκπτώσεων",
          "from": "2019.05.01",
          "to": "2019.05.10",
          "link": ""
        },
        {
          "message": "Καλοκαιρινές Εκπτώσεις ",
          "from": "2019.07.08",
          "to": "2019.08.31",
          "link": ""
        },
        {
          "message": "10ήμερο προσφορών",
          "from": "2019.11.01",
          "to": "2019.11.10",
          "link": ""
        }
      ],
      "openSundays": [
        "2019.01.20",
        "2019.04.21",
        "2019.05.05",
        "2019.07.14",
        "2019.11.03",
        "2019.12.15"
      ],
      "message": "cool"
    }
 */
export class MarketStructure {
  listOpenSundays: Array<EventStructure> = [];
  listSalePeriods: Array<EventStructure> = [];
  //From JSON
  openSundays: Array<string> = [];
  periods: Array<{ from: string, to: string, message: string, link: string }> = [];

  constructor(jsonObject: any = null) {
    Object.assign(this, jsonObject);
    this.listOpenSundays = [];
    if (this.openSundays != null) {
      for (let item of this.openSundays) {
        let dateSunday = UtilitiesApp.toURLDateFromString(item);
        if(dateSunday!=null) {
          this.listOpenSundays.push(EventStructure.eventUrl("Κυριακή (Ανοιχτά Καταστήματα)", "market.basket", dateSunday, null));
        }
      }
    }
    this.listSalePeriods = [];
    if (this.periods != null) {
      for (let item of this.periods) {
        let dateFrom = UtilitiesApp.toURLDateFromString(item.from);
        let dateTo = UtilitiesApp.toURLDateFromString(item.to);
        this.listSalePeriods.push(EventStructure.eventUrl(item.message, "market.sale", dateFrom, null).setDates(dateFrom, dateTo));
      }
    }
    //@ts-ignore
    this.listSalePeriods.sort((a, b) => a.dateFrom > b.dateFrom ? 1 : -1);
    //@ts-ignore
    this.listOpenSundays.sort((a, b) => a.dateFrom > b.dateFrom ? 1 : -1);
  }

  getClosesSalePeriod(dateFilter = new Date()): EventStructure|null {
    let closest = null;

    for (let eventPeriod of this.listSalePeriods) {
      //@ts-ignore
      if (eventPeriod.dateTo >= dateFilter)
        return eventPeriod;
    }
    return null;
  }

  getSalesAfter(dateFilter = new Date()):EventStructure[] {
    //@ts-ignore
    return this.listSalePeriods.filter(item => item.dateTo >= dateFilter);
  }

  getSundaysAfter(dateFilter = new Date()) {
    //@ts-ignore
    return this.listOpenSundays.filter(item => item.dateFrom >= dateFilter);
  }
}

export class ContentStructure {
  type: string = '';
  content: string = '';
  //properties: Map<string, string> = new Map<string, string>();
  properties: { [key: string]: string } = {};

  constructor(jsonObject: any | null = null) {
    Object.assign(this, jsonObject);

  }

  getData(): string | null {
    return this.properties != null && this.properties["data"] || null;
  }

  getStyle() {
    return this.properties != null && this.properties["style"] || null;
  }

  hasCommonSideBar() {
    return this.properties != null && (this.properties["show_common_bar"] == null || "true" === this.properties["show_common_bar"]);
  }
}

export class WeatherStructureOpen {

  /*Data converted
  * An array of arrays. One item per day with array of the 'forcast' hours*/
  arrayDays: {date:Date,items:{main:any,weather:any[]}[]}[];

  list: Array<any> = [];
  /*return code from request (200)*/
  cod: string | null = null;

  constructor(jsonObject: any | null) {
    if (jsonObject != null) {
      Object.assign(this, jsonObject);
    }
    if (this.isValid()) {
      //console.log("date is valid.. convert to array date..");
      this.arrayDays = this.toArrayDate();
    } else
      this.arrayDays = [];
  }

  isValid(): boolean {
    try {
      return this.cod == "200"
    } catch (error) {
      return false;
    }
  }

  getArrayDays() {
    return this.arrayDays;
  }

  count(): number {
    return this.arrayDays.length;
  }

  getFDate(indexDay = 0) {
    return this.arrayDays[indexDay].date;
  }

  getFTempFirst(indexDay = 0) {
    return Math.ceil(this.arrayDays[indexDay].items[0].main.temp);
  }

  getFTempMin(indexDay = 0) {
    let min;
    for (let item of this.arrayDays[indexDay].items) {
      if (!min || item.main.temp_min < min)
        min = item.main.temp_min;
    }
    return Math.ceil(min);
  }

  getFTempMax(indexDay = 0) {
    let max;
    for (let item of this.arrayDays[indexDay].items) {
      if (!max || item.main.temp_max > max)
        max = item.main.temp_max;
    }
    return Math.ceil(max);
  }

  /** {
          "id": 800,
          "main": "Clear",
          "description": "αίθριος καιρός",
          "icon": "01n"
     }**/
  getFCodeOfDay(indexDay = 0) {
    let size = this.arrayDays[indexDay].items.length;
    let indexTime = size == 8 ? 5 : (size - 1);
    return this.arrayDays[indexDay].items[indexTime].weather[0];
  }

  toArrayDate() {
    // Convert to map (group forecast of each day)
    let map = new Map<number, Array<any>>();

    //@ts-ignore
    for (let item of this.list) {
      let date = new Date(item.dt * 1000);
      let dateOnly = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
      //console.log("The date is:"+date+"[date-only]"+dateOnly);
      if (!map.has(dateOnly.getTime())) {
        map.set(dateOnly.getTime(), []);
      }
      //@ts-ignore
      map.get(dateOnly.getTime()).push(item);
    }

    // convert map to array
    let result : {date:Date,items:[]}[] = [];

    //@ts-ignore
    map.forEach((value: [], dateMs: number) => {
      result.push(
          {
            date: new Date(dateMs),
            items:value
          }
      )
    });
    // sort by date
    return result.sort((a, b) => {
      return a.date.getTime() > b.date.getTime() ? 1 : -1
    });
  }
}


export class UserStructure {
  token: string | null = null;
  username: string | null = null;
  id: number | null = null;

  constructor(jsonObject: any) {
    if (jsonObject != null)
      Object.assign(this, jsonObject);
  }

  getToken(): string | null {
    return this.token;
  }

  getUsername(): string | null {
    return this.username;
  }

  getId(): number | null {
    return this.id;
  }
}
