


























import {ComponentBasic} from '@/utilities/UtilitiesVue';
import * as moment from 'moment-timezone';
import ComponentDialog from "@/component/ComponentDialog.vue";
import {UtilitiesApp} from "@/utilities/UtilitiesApp";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Component({components: {ComponentDialog}})
export default class extends mixins(ComponentBasic) {
    //'dialog-component': ComponentDialog
    LN: string = "[App.vue]";

    isShowSideBar: boolean = UtilitiesApp.staticSettings().isShowSideBar;
    isMiniDrawer: boolean = true;

    notificationShow: boolean = false;
    notificationMessage: string = '';

    dialogTitle: string = '';
    dialogContent: string = '';
    dialogShow: boolean = false;

    /*default*/
    //backColor: string = '#e8e8e8';
    backColor: string = '#f4f4f4';

    styleContainer: string = 'max_width_1500';

    /*default*/
    backImage: string = '';

    /**
     * 1. Load Data (settings & content-map)
     * 2. Page(s) using watchers to display the needed content OR errors
     */
    created() {
        this.Log("Created, setting periodically loading config");
        this.LoadSettings();
        this.LoadSummary();
        this.EventRegister('notify', this.OnNotify);
        moment.tz.add("Europe/Athens|AMT EET EEST CEST CET|-1y.Q -20 -30 -20 -10|012123434121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2a61x.Q CNbx.Q mn0 kU10 9b0 3Es0 Xa0 1fb0 1dd0 k3X0 Nz0 SCp0 1vc0 SO0 1cM0 1a00 1ao0 1fc0 1a10 1fG0 1cg0 1dX0 1bX0 1cQ0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|35e5");
        //this.$options.interval = setInterval(this.LoadSummary, 300000);
    }

    @Watch('$route')
    onRouteChange() {
        console.log("Updated route");
        console.log(this.$route);
        if (this.$route && this.$route.path && this.$route.path.includes("admin")) {
            this.styleContainer = '';
        } else {
            this.styleContainer = 'max_width_1500';
        }
    }

    @Watch('settings')
    onSettingsChange(val: any) {
        this.Log(this.LN, "Setting Loaded, set backColor and other..");
        //@ts-ignore
        this.backColor = this.settings.backColor;
        //@ts-ignore
        this.backImage = this.settings.backImage;
        //@ts-ignore
        this.isShowSideBar = this.settings.isShowSideBar;
    }


    onClickDrawerItem(path: any) {
        this.isMiniDrawer = true;
        this.GoToRouter(path);
    }

    OnNotify(message: string) {
        this.Log("On notify");
        this.notificationShow = true;
        this.notificationMessage = message;
    }


    beforeDestroy() {
        this.Log("Destroy configLoader Timer");
        //@ts-ignore
        clearInterval(this.$options.interval)
    }
}
