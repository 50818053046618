<template>
  <v-card>

    <slot name="toolbar">
      <v-toolbar :color="color!=null ? color : 'light-blue'" dark dense v-if="title">
        <v-toolbar-side-icon></v-toolbar-side-icon>
        <v-toolbar-title class="text-md-center" style="font-size: 12px;">{{title}}</v-toolbar-title>
      </v-toolbar>
    </slot>

    <v-list v-if="items">
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item :href="item.url" @click="goTo(item.path)" dense >
            <v-list-item-icon v-if="item.icon || item.iconClass">
              <v-icon v-if="item.icon">{{item.icon}}</v-icon>
              <li v-if="item.iconClass" :class="item.iconClass" :style="item.iconStyle"></li>
            </v-list-item-icon>
            <v-list-item-content style="padding:10px">
              <v-list-item-title v-html="item.title" style="font-weight:normal"></v-list-item-title>
              <v-list-item-subtitle v-if="showLink">
                {{item.url}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index !== (items.length-1)"/>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
  import {ComponentBasic} from "@/utilities/UtilitiesVue";

  export default  {
    mixins: [ComponentBasic],
    props: {
      items: Array,
      title: String,
      color: String,
      showLink: Boolean,
    },
    data() {
      return {
        LN: "[LinkBarList]::"
      }
    },
    methods: {
      goTo(path) {
        if (path != null) {
          this.$router.push({path: path});
        }
      }
    }
  }

</script>
