/**
 */
export class UtilitiesBasic {

    static Log(ln: string, message: string, object ?: any | null): void {
        console.log(ln + " " + message);
        if (object != null)
            console.log(object)
    }


    static Debug(ln: string, message: string, object ?: any): void {
        console.log(ln + " " + message);
        if (object != null)
            console.log(object);
    }


    static isMobile(): boolean {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }


    static getStringToColor(str:string):string {
        let hash:number = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    static getGoogleURL(name: string): string {
        return "https://www.google.com/search?q=" + name;
    }

    static getCurrentLink(): string {
        try {
            return window.location.href;
        } catch (error) {
            return '';
        }
    }

    static toStringNoAccent(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    static getHostnameFromURL(url: string = "") {
        if (url == null)
            return "";
        let hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        url = url.toLowerCase();
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname.replace("www.", "");
    }




}
