




































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Language, UtilitiesApp} from "@/utilities/UtilitiesApp";
import Component, {mixins} from "vue-class-component";
import ChartStats from "@/component/chart/ChartStats.vue";
import {EventStructure, StatsStructure} from "@/model/ModelApp";
import {Watch} from "vue-property-decorator";

@Component({components: {ChartStats}})
export default class extends mixins(ComponentBasic) {
    /* components: {
         'chart-stats': ChartStats,
     },*/
    LN: string = "[PageStats]::";
    payload: PayloadStructure<StatsStructure> = PayloadStructure.loading(Language.pleaseWait);
    listEvents: Array<EventStructure> | null = null;
    spanResultSummary: string | null = null;
    dateDetails: string | null = null;
    color: string = 'white';

    get hasCommonSideBar() {
        return true;
    }

    calcContent() {
        this.spanResultSummary = "";
        this.payload = PayloadStructure.loading(Language.pleaseWait);

        this.GetEventStatsPromise(null, null, null /*all categories*/)
            .then((stats:StatsStructure) => {
                this.payload = PayloadStructure.info(stats);
                try {
                    if (stats.getTotalCount() != null)
                        this.spanResultSummary = "Σύνολο " + stats.getTotalCount();
                } catch (err) {
                    this.spanResultSummary = "";
                }
            }).catch((error:any) => {
                this.payload = PayloadStructure.error(Language.error)
            }
        )
    }

    onClickedChart(e:Date) {
        this.dateDetails = UtilitiesApp.toURLStringFromDate(e);
        this.Log("Parent clicked chart set date:" + this.dateDetails);
    }

    created() {
        this.calcContent();
    }

    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }


}
