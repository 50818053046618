<template>
  <!--  ? id: $options._componentTag +'-'+ _uid -->
  <div :id="id" style="width: 100%; height: 100%;">
    <slot></slot>
  </div>
</template>
<script>

    export default {
        data() {
            return {
                LN: "[AceEditor]::",
                editor:null,
                options: { mode: 'html',
                    theme: 'github',
                    fontSize: 11,
                    fontFamily: 'monospace',
                    highlightActiveLine: false,
                    highlightGutterLine: false}
            }
        },
        props: {
            value: String,
            id: String,
        },
        watch: {
            value() {
                //  two way binding – emit changes to parent
                this.$emit('input', this.value);

                //  update value on external model changes
                if (this.oldValue !== this.value) {
                    this.editor.setValue(this.value, 1);
                }

                console.log("value:"+this.value);
            }
        },

        mounted() {
            //const lang = this.lang || 'text';
            //const theme = this.theme || 'github';

            //  editor
            this.editor = window.ace.edit(this.$el.id);

            //  deprecation fix
            //this.editor.$blockScrolling = Infinity;

            //  ignore doctype warnings
            const session = this.editor.getSession();
            session.on("changeAnnotation", () => {
                const a = session.getAnnotations();
                const b = a.slice(0).filter((item) => item.text.indexOf('DOC') == -1);
                if (a.length > b.length) session.setAnnotations(b);
            });

            //  editor options
            //  https://github.com/ajaxorg/ace/wiki/Configuring-Ace
            this.options = this.options || {};

            //  opinionated option defaults
            //this.options.maxLines = this.options.maxLines || Infinity;
            //this.options.printMargin = this.options.printMargin || false;
            //this.options.highlightActiveLine = this.options.highlightActiveLine || false;

            //  hide cursor
            if (this.options.cursor === 'none' || this.options.cursor === false) {
                this.editor.renderer.$cursorLayer.element.style.display = 'none';
                delete this.options.cursor;
            }

            //  add missing mode and theme paths
            if (this.options.mode && this.options.mode.indexOf('ace/mode/') === -1) {
                this.options.mode = `ace/mode/${this.options.mode}`;
            }
            if (this.options.theme && this.options.theme.indexOf('ace/theme/') === -1) {
                this.options.theme = `ace/theme/${this.options.theme}`;
            }

            //this.editor.getSession().setMode(`ace/mode/${lang}`);
            //this.editor.setTheme(`ace/theme/${theme}`);

            this.editor.setOptions(this.options);


            //  set model value
            //  if no model value found – use slot content
            if (!this.value || this.value === '') {
                this.$emit('input', this.editor.getValue());
            } else {
                this.editor.setValue(this.value, -1);
            }

            //  editor value changes
            this.editor.on('change', () => {
                //  oldValue set to prevent internal updates
                this.value = this.oldValue = this.editor.getValue();
            });


        },
        methods: {
          /*  editor() {
                return this.editor
            }*/
        }
    }
</script>
<style>
  ace-editor {
    margin: 2rem 0;
    border: 1px solid #eee;
  }
</style>

