<template>
  <v-row>
    <!-- bar with frame-->
    <v-col :cols="isExpand ? 12 : 9">
      <v-row>

        <!-- Row 2-->
        <v-col cols="12" >
          <v-card>
            <v-card-title style="padding:0">
              <v-row>
                <v-col cols="11">
                  <v-row style="padding:0">
                    <v-col cols="2" class="text-center" v-for="item in calendarItems" :key="item.id"
                           v-if="item.isShowInUi">

                      <div style="margin-bottom: 10px;margin-top: 10px;cursor:pointer;"
                           @click="onClickItem(item)">

                        <div style="padding:10px 10px 10px 10px">

                          <div class="calendar-item-back"
                               v-bind:style="{ backgroundImage: 'url(' + item.icon+ ')'}">

                            <img v-if="item.isChecked"
                                 src="extra/img/calendar/check.80.png"
                                 class="center-image"/>

                          </div>

                          <h5
                            v-bind:style="{ textTransform: 'none', borderBottom : '5px solid ' + item.color, fontSize: '12px'}">
                            {{item.name}}
                          </h5>

                        </div>
                      </div>

                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-btn fab dark small color="blue" @click="onExpand">
                    <v-icon v-if="!isExpand">fullscreen</v-icon>
                    <v-icon v-if="isExpand">fullscreen_exit</v-icon>
                  </v-btn>
                  <br/>
                  <v-btn fab dark small color="red">
                    <i class="fas fa-plus"></i>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
        <!------------- IFrame ------------------->
        <v-col cols="12" style="padding-left:0;padding-right:0;padding-bottom:0">
          <v-card>

            <iframe id="calendar_frame"
                    v-bind:src="calendarLink"
                    width="100%"
                    v-bind:height="calendarHeight"
                    frameborder="0">
            </iframe>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="3" v-if="!isExpand">
      <apergia-side-bar></apergia-side-bar>
    </v-col>

  </v-row>
</template>
<!--lang="ts"-->
<script>

  import {InfoMessageStructure} from '@/model/basic/ModelBasic';
  import {ComponentBasic} from "@/utilities/UtilitiesVue";
  import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
  import Component, {mixins} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";
  import {UtilitiesApp} from "@/utilities/UtilitiesApp";

  /* @Component
   export default class ComponentCalendar extends mixins(ComponentBasic) {

   }*/
  export default {
    mixins: [ComponentBasic],
    data() {
      return {
        LN: "[Calendar]::",
        isExpand: UtilitiesBasic.isMobile(),
        message: InfoMessageStructure.info('αναλυτικά όλες οι απεργίες καταγράφονται στο ημερολόγιο παρακάτω.'),
        calendarItems: UtilitiesApp.getCalendarViewCategories()
      }
    },
    computed: {
      calendarLink: function () {
        this.Log("Recalculate calendar-url");
        let defaultBase = "http://www.google.com/calendar/embed?showTitle=0&showNav=0&showDate=1&showPrint=1&showTabs=1&showCalendars=0&showTz=0&mode=MONTH&wkst=2&bgcolor=%23FFFFFF&hl=en-GB&";
        //Use height only in iframe
        //let defaultBase = "http://www.google.com/calendar/embed?showTitle=0&showNav=0&showDate=1&showPrint=1&showTabs=1&showCalendars=0&showTz=0&mode=MONTH&wkst=2&bgcolor=%23FFFFFF&hl=en-GB&height=";
        if (this.settings != null && this.settings.calendarBaseURL != null)
          defaultBase = this.settings.calendarBaseURL;
        let urlConstruct = defaultBase /*+ this.calendarHeight */;
         for (let item of this.calendarItems) {
          if (item.isChecked) {
            urlConstruct += "src=" + item.id + "&color=" + item.color.replace('#', '%23') + "&";
          }
        }
        this.Log(urlConstruct);
        return urlConstruct;
      },
      /* Not sure that has any effect */
      calendarHeight: function () {
        //this.Log("Calc height using server settings:" + (this.getSettings != null && this.getSettings.calendarHeightPx != null));
        if (this.settings != null && this.settings.calendarHeightPx != null) {
          try {
            return parseInt(this.settings.calendarHeightPx)+"px";
          } catch (err) {
            this.Log(err);
          }
        }
        return "1500px";
      }
    },
    methods: {
      onClickItem(item) {
        item.isChecked = !item.isChecked;
      },
      onExpand() {
        this.isExpand = !this.isExpand;
      }
    },
  }
</script>

<style scoped>

  .v-btn {
    margin: 8px
  }

  .calendar-container-back {
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    outline: none;
  }

  .calendar-item-back {
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
    border: 1px solid #dfdfdf;
    display: inline-block;
    font-size: 30px;
    height: 75px;
    padding: 0;
    width: 75px;
    margin-top: 0;
    /*background: #FFF  no-repeat fixed center;*/
    /* background-image: url("../img/calendar/mmm.40.png");*/
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
