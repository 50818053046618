
















import {ComponentBasic} from "@/utilities/UtilitiesVue";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Component({})
export default class extends mixins(ComponentBasic) {
    LN: string = "[PageContent]::";
    dynamicComponent: any | null = null;
    contentStructure:any | null = null;

    get hasCommonSideBar(){
        return !!this.contentStructure && this.contentStructure.hasCommonSideBar();
    }

    calcContent(){
        let type = this.$route.params.type;
        this.contentStructure = this.contentMap.get(type);
        this.dynamicComponent = this.CreateComponentOfType(type);
    }

    created(){
        this.calcContent();
    }

    @Watch('contentMap')
    onContentMap(val:any){
        this.calcContent();
    }

    // eslint-disable-next-line
    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }
}
