






































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import LinkBarList from '@/component/basic/LinkBarList.vue';
import Component, {mixins} from "vue-class-component";
import {TypePath, TypeContent, UtilitiesApp} from "@/utilities/UtilitiesApp";

@Component({components: {LinkBarList}})
export default class extends mixins(ComponentBasic) {

    linksMMM = [
        {title: 'ΟΣΥ (Λεωφορεία &amp; Τρόλεϊ)', url: 'http://www.osy.gr/', icon: 'directions_bus'},
        {title: 'ΣΤΑΣΥ (Μετρο, ΗΣΑΠ &amp; Τράμ)', url: 'http://www.stasy.gr/', icon: 'directions_railway'},
        {title: 'Προαστιακός', url: 'http://www.trainose.gr/', icon: 'directions_railway'},
        {title: 'ΟΣΕ', url: 'http://www.ose.gr/', icon: 'directions_railway'},
        {title: 'Αεροδρόμιο', url: 'http://www.aia.gr/', icon: 'flight'},
        {title: 'Λιμάνι Πειραία', url: 'http://www.olp.gr/el/interactive-port-map', icon: 'directions_boat'},
    ];

    linksPhones = [
        {title: 'ΟΑΣΑ 11185 (αστ. χρέωση)', url: 'tel:11185', icon: 'phone'},
        {title: 'Ελ. Βενιζέλος +30 210 3530000', url: 'tel:+302103530000', icon: 'phone'},
        {title: 'Λιμανι Πειραία 14541 ', url: 'tel:14541', icon: 'phone'},
        {title: 'Προαστικός/ΤΡΑΙΝΟΣΕ 14511', url: 'tel:14511', icon: 'phone'},
    ];

    listLinks = [
        {title: 'Προβολή Εβδομάδας', path: UtilitiesApp.toURLEvents(), iconClass: 'fa fa-bullhorn', iconStyle: 'color:gray;font-weight:bold'},
        {title: 'Καιρός', path: TypePath.Weather.path, iconClass: 'wi wi-day-cloudy-windy', iconStyle: 'color:gray;font-weight:bold'},
        {title: 'Εορτολόγιο', path: TypePath.NameDays.path, icon: 'card_giftcard'},
        {title: 'Επίσημες Αργίες', path: TypePath.Holidays.path, icon: 'hotel'},
        {title: 'Καταστήματα/Εκπτώσεις', path: TypePath.Market.path, icon: 'shopping_basket'},
        {title: 'Αρχείο/Αναζήτηση', path: TypePath.Archive.path, icon: 'search'},
        {title: 'Στατιστικά', path: TypePath.Stats.path, icon: 'show_chart'},
        {title: 'Χρήσιμα Τηλέφωνα', path: TypePath.Content.path + '/' + TypeContent.Phones, icon: 'phone'}
    ]
}
