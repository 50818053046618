














import {ComponentBasic} from '@/utilities/UtilitiesVue';
import {mixins} from "vue-class-component";
import {Component, Watch} from "vue-property-decorator";
import {SettingsStructure} from "@/model/ModelApp";

@Component({})
export default class FrontMessage extends mixins(ComponentBasic) {
    LN: string = "[FrontMessage]::";
    dynamicComponent: any | null = null;

    created() {
        this.calculateMessage('created');
    }

    calculateMessage(trigger: string) {
        this.Log("Calculating Front Message [" + trigger + "]");
        if (this.settings != null && this.contentMap != null) {
            this.dynamicComponent = this.CreateComponentOfType("main");
        } else {
            this.Log("settings or map is Null");
        }
    }

    @Watch('settings')
    onSettingsUpdated(val: SettingsStructure) {
        this.calculateMessage("settings-changed");
    }
}
