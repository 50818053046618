






























































import {ComponentBasic} from '@/utilities/UtilitiesVue';
import {UtilitiesApp} from "@/utilities/UtilitiesApp";
import Component, {mixins} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {EventStructure} from "@/model/ModelApp";

@Component
export default class ComponentHoliday extends mixins(ComponentBasic) {
  LN: string = "[ComponentHoliday]::";
  yearSelected: number = 2018;

  @Prop({default: new Date().getFullYear()})
  year!: number;

  @Prop({default: true})
  selectable!: boolean;

  @Prop({default: 13})
  fontSize!: number;

  @Prop({default: 'dddd D MMMM'})
  formatDate!: string;

  created() {
    if (this.year != null)
      this.yearSelected = this.year;
  }

  setSelectedYear(year: number) {
    this.yearSelected = year;
  }

  get statistics(): { week: number, total: number } {
    let count = 0;
    this.holidayList.forEach(item => {
      if (item.getDateFrom()!.getDay() != 0 && item.getDateFrom()!.getDay() != 6)
        count++;
    });
    return {week: count, total: this.holidayList.length};
  }

  get holidayList(): EventStructure[] {
    return UtilitiesApp.getHolidayOf(this.yearSelected);
  }

  get listYear(): number[] {
    return [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  }
}


