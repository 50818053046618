



















































































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {Language, UtilitiesApp, WeatherURLs} from "@/utilities/UtilitiesApp";
import {Prop, Watch} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
import {WeatherStructureOpen} from "@/model/ModelApp";

@Component({})
export default class extends mixins(ComponentBasic) {

    selected: string = "";
    LN: string = "[WeatherLine]::";
    /*depends From count days*/
    weatherUrls: { value: string, name: string }[] = WeatherURLs;
    weatherSelected: { value: string, name: string } = WeatherURLs[0];
    payload: PayloadStructure<any> = PayloadStructure.loading(Language.pleaseWait);

    @Prop({default: '5'})
    countDays!: string; //2,3,5 accepted

    @Prop({default: true})
    showLink!: Boolean;

    get hasCommonSideBar() {
        return !UtilitiesBasic.isMobile();
    }

    created() {
        this.calcContent();
    }

    calcContent() {
        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.Log("Calc content using [" + this.weatherSelected + "]");
        let value: string = this.weatherSelected.value;
        RestClientApergia.getCache(null, null, value)
            .then((value) => {
                let structure = new WeatherStructureOpen(value.data);
                this.Log("Weather loaded"/*, structure*/);
                if (structure.isValid())
                    this.payload = PayloadStructure.info(structure);
                else
                    this.payload = PayloadStructure.error("Failed Loading Data");
            })
            .catch((error) => {
                this.Log("Failed loaded weather", error);
                this.payload = PayloadStructure.error(Language.error)
            });
    }


    @Watch('weatherSelected')
    onWeatherSelected(value: { value: string, name: string }) {
        this.Log("Weather selected [" + value + "]");
        this.calcContent();
    }

}

