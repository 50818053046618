




















































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {UtilitiesApp} from "@/utilities/UtilitiesApp";
import {mixins} from "vue-class-component";
import {Component} from "vue-property-decorator";
import ComponentHoliday from "@/component/ComponentHoliday.vue";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

@Component({components: {ComponentHoliday}})
export default class PageHoliday extends mixins(ComponentBasic) {
    LN: string = "[PageHolidays]::";
    year: number = 2018;

    get closestHoliday() {
        return UtilitiesApp.getHolidayClosest();
    }

    get hasCommonSideBar() {
        return !UtilitiesBasic.isMobile();
    }
}
