










import {StatsStructure} from "@/model/ModelApp";
import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";

@Component
export default class ChartStats extends mixins(ComponentBasic) {
  LN = '[ChartStats]::';
  chartType = 'Spline';
  updateArgs:any = [true, true, { duration: 1000 }];

  @Prop()
  stats!: StatsStructure;

  chartOptions(callback:any): any {
    //@ts-ignore
    return {
      chart: {
        zoomType: 'x',
        height: 500,
        type:'Spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Click and drag in the plot area to zoom in'
      },
      xAxis: {
        type: 'datetime',
        title:
          {
            text: 'Ημερομηνία'
          }
      },
      yAxis: {
        title: {
          text: 'Σύνολο'
        }
      },
      series: [
        {
          showInLegend: false,
          type: 'area',
          name: 'Όλες',
          data: this.stats.getSeriesDefault(),
          color: '#88a8ff',
          lineWidth: 0.5,
          events: {
            click: function (e:any) {
              callback(e)
            }
          }
        }
      ],
    }
  };

  onClickChartPoint(e: any) {
    //@ts-ignore
    this.Log("chart clicked " + e.point.category);
    //@ts-ignore
    this.$emit('event-clicked-chart', e.point.category);
  }

  @Watch("title")
  onTitleChange(value:string) {
    //@ts-ignore
    this.chartOptions.title.text = value
  }


  @Watch("chartType")
  onChartTypeChanged(value:string) {
    //@ts-ignore
    this.chartOptions.chart.type = value.toLowerCase()
  }
}
