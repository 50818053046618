import moment, {min} from 'moment';
import {UtilitiesMath} from "@/utilities/basic/UtilitiesMath";

export class UtilitiesDate {
  static DateFormat_DMY_HM: string = 'DD/MMM/YYYY HH:mmZ';
  static DateFormat_DMY_HM_NO_ZONE: string = 'DD/MMM/YYYY HH:mm';
  static DateFormat_DM_HM_NO_ZONE: string = 'DD/MMM HH:mm';
  static DateFormat_DM_HM: string = 'DD/MMM HH:mmZ';
  static DateFormat_DM: string = 'DD/MMM';
  static DateFormat_DMY: string = 'DD/MMM/YYYY';
  static DateFormat_DMMY: string = 'DD/MM/YYYY';
  static DateFormat_HHMMSS: string = 'HH:mm:ss.SS';

  static DatePeriodList: Array<any> = [
    {title: 'Last 2 hours', icon: 'date_range', hours: 2,},
    {title: 'Last 12 hours', icon: 'date_range', hours: 12,},
    {title: 'Last Day', icon: 'date_range', hours: 24,},
    {title: 'Last 3 Days', icon: 'date_range', hours: 3 * 24,},
    {title: 'Last 10 Days', icon: 'date_range', hours: 10 * 24,},
    {title: 'Last Month', icon: 'date_range', hours: 30 * 24,},
    {title: 'Custom Period', icon: 'date_range', hours: null}
  ];

  static formatPeriodHtml(dateFrom: Date, dateMiddle: Date | null | undefined, dateTo: Date, format: string = UtilitiesDate.DateFormat_DMMY, isHtml: boolean = true) {
    if (isHtml)
      return this.formatDateColor(dateFrom, format, isHtml) + (dateMiddle ? "<strong>(ETB " + moment(dateMiddle).format(format) + ")</strong>" : '') + ' - ' + this.formatDateColor(dateTo, format, isHtml);
    else
      return moment(dateFrom).format(format) + (dateMiddle ? "(ETB " + moment(dateMiddle).format(format) + ")" : '') + ' - ' + moment(dateTo).format(format);
  }

  static formatDateColor(date: Date, format: string, isHtml: boolean = true): string {
    if (isHtml) {
      let style = new Date().getTime() < date.getTime() ? 'color:blue' : 'color:gray';
      return "<span style='" + style + "'>" + moment(date).format(format) + "</span>";
    }
    return moment(date).format(format);
  }

  static getDaysDiff(dateStart: Date, dateEnd: Date, postFix: string): string {
    return UtilitiesMath.roundOne(moment(dateEnd).diff(moment(dateStart), 'days', true)) + postFix || '';
  }

  static getDateDiffInHoursNumber(dateStart: Date, dateEnd: Date = new Date()): number {
    return UtilitiesMath.roundOne(moment(dateEnd).diff(moment(dateStart), 'hours', true));
  }

  static getDateDiffInString(dateStart: Date, dateEnd: Date = new Date()): string {
    let msDAY = 24 * 3600 * 1000;
    let msHOUR = 3600 * 1000;
    let msMIN = 60 * 1000;
    let msSEC = 1000;

    let diff = (dateEnd.getTime() - dateStart.getTime());
    let days = Math.floor(diff / msDAY);
    diff -= msDAY * days;
    let hours = Math.floor(diff / msHOUR);
    diff -= msHOUR * hours;
    let mins = Math.floor(diff / msMIN);
    diff -= msMIN * mins;
    let secs = diff / msSEC;
    if (days == 0 && hours == 0 && mins == 0)
      return "few sec(s)";
    return (days > 0 ? days + "day(s) " : '') + (hours > 0 ? hours + "hours(s) " : '') + (mins > 0 ? mins + "min(s) " : '');
  }


  /**
   * "2020-08-12T13:13:42.238Z"
   * @param dateString
   */
  static parseDate(dateString: string): Date | null {
    try {
      if (dateString != null) {
        let partDateTime = dateString.split("T");
        let partDate: string[] = partDateTime[0].split("-");
        let partTime = partDateTime[1].split(":");
        let partSeconds = partTime[2].split("+");
        partSeconds = partSeconds[0].split(".");
        let date = new Date(Date.UTC(Number(partDate[0]), Number(partDate[1]) - 1, Number(partDate[2]), Number(partTime[0]), Number(partTime[1]), Number(partSeconds[0]), 0));
        return date;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  static format(date: Date | null, format: string = UtilitiesDate.DateFormat_DMY_HM, isUTC: boolean = false): string {
    //@ts-ignore
    let mom: moment.Moment = moment(date);
    if (isUTC)
      mom = mom.utc();
    return date ? mom.format(format) : "n/a";
  }


  static formatDynamic(date: Date | string | undefined, format: string, forceUTC: boolean = true) {
    if (!date)
      return '-';
    if ('string' == typeof date) {
      date = new Date(Number(date));
    }
    if (forceUTC)
      return moment(date).utc().format(format);
    else
      return moment(date).format(format);
    //return UtilitiesDate.format(date, dateOnly ? UtilitiesDate.DateFormat_DM : UtilitiesDate.DateFormat_DM_HM_NO_ZONE)
  }

  static formatDate(date: Date | null): string {
    return UtilitiesDate.format(date, UtilitiesDate.DateFormat_DMY)
  }

  static formatDateTime(date: Date | null): string {
    return UtilitiesDate.format(date, UtilitiesDate.DateFormat_DMY_HM)
  }

  static formatFromNow(date: Date | null): string {
    return date != null ? moment(date).fromNow() : ''
  }

  static daysBefore(number: number) {
    let date = new Date();
    date.setDate(date.getDate() - number);
    return date;
  }
}

