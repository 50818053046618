














































































//import Chart from '@/component/chart/Chart';
import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {UtilitiesApp, UtilDate, Language} from "@/utilities/UtilitiesApp";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {EventStructure, EventsWrapperStructure} from "@/model/ModelApp";
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";

@Component({})
export default class PageArchive extends mixins(ComponentBasic) {
    LN: string = "[PageArchive]::";

    payload: PayloadStructure<EventsWrapperStructure> = PayloadStructure.loading(Language.pleaseWait);
    listEvents: EventStructure[] | null = null;
    categoryItem: { text: string, value: string | null } | null = null;
    textFilter: string | null = null;
    spanResultSummary: string | null = null;
    categoryAll: { text: string, value: string | null }[] = UtilitiesApp.getCalendarSearchCategories();
    dateFrom: Date | null = null;
    dateTo: Date | null = null;
    color: string | null = 'white';

    get hasCommonSideBar() {
        return !UtilitiesBasic.isMobile();
    }

    calcContent() {
        this.spanResultSummary = "";
        this.payload = PayloadStructure.loading(Language.pleaseWait);
        this.dateFrom = null;//UtiltiiesApp.toURLDateFromString(this.$route.params.dateFromText);
        this.dateTo = null;//UtiltiiesApp.toURLDateFromString(this.$route.params.dateToText);

        this.Log("Period [" + UtilDate.toStringDatePretty(this.dateFrom) + "-" + UtilDate.toStringDatePretty(this.dateTo) + "]");

        this.GetEventSearchPromise(this.dateFrom, this.dateTo, this.categoryItem == null ? null : this.categoryItem.value, this.textFilter, true)
            .then((eventWrapper: EventsWrapperStructure) => {
                //@ts-ignore
                this.listEvents = eventWrapper.listEvents.sort((a, b) => a.dateFrom > b.dateFrom ? -1 : 1);

                this.payload = PayloadStructure.info(eventWrapper);
                this.spanResultSummary = "Σύνολο " + eventWrapper.listEvents.length;
            }).catch((error) => {
                this.payload = PayloadStructure.error(Language.error)
            }
        );
    }

    filterEvents() {
        return this.listEvents;
    }

    created() {
        this.Log("On Created [" + this.$route.params.search + "]");
    }

    beforeMount() {
        this.Log("On BeforeMount a[" + this.$route.params.dateFromText + "]");
        this.Log("On BeforeMount b[" + this.$route.params.search + "]");
        this.textFilter = this.$route.params.search;
        this.calcContent();
    }

    /*
    beforeRouteUpdate(to, from, next) {
        next();
    }*/

    @Watch('settings')
    onSettings() {
        this.calcContent();
    }

    @Watch('$route')
    onRouteUpdated() {
        this.Log("route updated?");
        this.calcContent();
    }

}
