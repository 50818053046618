import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/store'
import vuetify from '@/plugins/vuetify';
//@ts-ignore
import HighchartsVue from 'highcharts-vue'
import VueCookie from 'vue-cookies-ts'

//import VueGtag from 'vue-gtag';
//const VueGtag = require('vue-gtag');


import ComponentCalendar from '@/component/ComponentCalendar.vue';
import ComponentSideBar from '@/component/ComponentSideBar.vue';
import ComponentCardShareBar from '@/component/basic/CardShareBar.vue';
import ComponentPayloadMessage from '@/component/ComponentPayloadMessage.vue';

import AdComponent from '@/component/basic/AdComponent.vue';
import AceEditor from '@/component/basic/AceEditor.vue';
import ComponentWeather from '@/component/basic/ComponentWeather.vue';

import ListCarousel from '@/component/auto/ListCarousel.vue'
import ListSummary from '@/component/auto/ListSummary.vue'
import ListTile from '@/component/auto/ListTile.vue'
import ListEvent from '@/component/list/ListEvent.vue'
import ListNews from "@/component/list/ListNews.vue";
import ListYoutube from "@/component/list/ListYoutube.vue";
import ListTrends from "@/component/list/ListTrends.vue";
import ComponentFrontMessage from "@/component/ComponentFrontMessage.vue";

//Highcharts Adding Module
import Highcharts from 'highcharts'
import HighchartSeriesLabel from 'highcharts/modules/series-label'
import ChartCovnid19 from "@/component/chart/ChartCovid19.vue";
import ListNamedays from "@/component/list/ListNamedays.vue";
HighchartSeriesLabel(Highcharts);

console.log("Register Global Components ");
Vue.component('calendar-component', ComponentCalendar);
Vue.component('front-message-component', ComponentFrontMessage);
Vue.component('apergia-side-bar', ComponentSideBar);
Vue.component('ad-component', AdComponent);
Vue.component('payload-message-component', ComponentPayloadMessage);
Vue.component('list-summary', ListSummary);
Vue.component('list-carousel', ListCarousel);
Vue.component('list-tile', ListTile);
Vue.component('list-event', ListEvent);
Vue.component('list-namedays', ListNamedays);
Vue.component('list-news', ListNews);
Vue.component('list-youtube', ListYoutube);
Vue.component('list-trends', ListTrends);
Vue.component('card-share-bar', ComponentCardShareBar);
Vue.component('ace-editor', AceEditor);
Vue.component('weather-component', ComponentWeather);
Vue.component('chart-convid19', ChartCovnid19);

Vue.use(VueCookie);
Vue.use(HighchartsVue);
Vue.use(require('vue-script2'));
//Vue.use(require('vue-gtag'), {config: { id: "UA-1690348-3" }}, router);
//Vue.use(VueAnalytics, {id: 'UA-1690348-3', router:AppVueRouter});
//Vue.use(require('vue-analytics'), { id: "UA-1690348-3" , router});
console.log("INIT-Init Vue Instance");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
