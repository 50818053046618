import Vue from 'vue';
import {UtilitiesBasic} from "@/utilities/basic/UtilitiesBasic";
import moment from 'moment-timezone';
import {EventsWrapperStructure, EventStructure, SettingsStructure, SummaryStructure, StatsStructure, UserStructure} from "@/model/ModelApp";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {PayloadStructure, TypeMessage} from "@/model/basic/ModelBasic";
import {UtilDate, TypePath, TypeContent, UtilitiesApp} from "./UtilitiesApp";
import Vuetify, {
    VCard, VCardText, VCardActions, VCardTitle,
    VSpacer, VIcon, VDivider,
    VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, VListItemIcon, VListItemTitle, VListItemActionText, VListItemSubtitle, VListItemContent, VListItemGroup,
    VTab, VTabItem, VTabs,
    VToolbar, VToolbarTitle, VToolbarItems, VContainer, VCol, VRow, VChip, VAvatar
} from 'vuetify/lib'
import {Component} from "vue-property-decorator";
import {UtilitiesMath} from "@/utilities/basic/UtilitiesMath";


export const EventBus = new Vue();

@Component
export class ComponentBasic extends Vue {
    LN: string = "n/a";
    TypePath = TypePath;
    TypeMessage = TypeMessage;
    TypeContent = TypeContent;
    /*TODO remove */
    UtilConvert = UtilitiesApp;
    Util = {UtilitiesBasic, UtilitiesApp, UtilDate, UtilitiesMath, /*TODO remove */ UtilConvert: UtilitiesApp}
    Type = {TypeMessage, TypeContent}

    UtilBasic() {
        return UtilitiesBasic;
    }

    UtilDate() {
        return UtilDate;
    }

    Moment(date?: Date) {
        return moment(date);
    }

    //Computed Property
    getToken(): string | null {
        return this.user != null ? this.user.getToken() : null;
    }

    get user(): UserStructure {
        return this.$store.getters.getUser;
    }

    get settingsPayload() {
        return this.$store.getters.getSettingsPayload;
    }

    get settings(): SettingsStructure {
        return this.$store.getters.getSettings;
    }

    get summaryPayload(): PayloadStructure<SummaryStructure> {
        return this.$store.getters.getSummaryPayload;
    }

    get summary(): SummaryStructure | null {
        return this.$store.getters.getSummaryPayload.hasData() ? this.$store.getters.getSummaryPayload.getPayload() : null;
    }

    get isMobile() {
        return UtilitiesBasic.isMobile();
    }

    get contentMap(): Map<string, { content: string, properties?: { show_common_bar?: string } }> {
        return this.$store.getters.getContentMap;
    }

    getContent(key: string) {
        return this.$store.getters.getContent;
    }

    GetNewsPayload() {
        return this.$store.getters.getNewsPayload;
    }

    Log(msg: string = '', obj: any | null = null) {
        //this.$ga.page('/'+this.LN);
        UtilitiesBasic.Log(this.LN + msg, obj);
    }

    EventThrow(type: string, structure: any) {
        EventBus.$emit(type, structure);
    }

    EventRegister(type: string, callback: any) {
        EventBus.$on(type, callback);
    }

    GoToRouter(path: string) {
        this.Log("Go to [" + path + "]");
        //@ts-ignore
        //this.$gtag.event('page', { method: path });
        path = path || TypePath.Home.path;
        this.$router.push({path: path});
    }

    OnLoginPromise(username: string, password: string) {
        return RestClientApergia.adminLoginUser(null, null, username, password)
            .then(value => {
                let user = new UserStructure(value.data);
                this.$store.commit('setUser', user);
                this.$cookies.set("apergiaToken", user.getToken());
                return Promise.resolve(user);
            });
    }

    OnLoginCookie(): Promise<UserStructure> {
        //@ts-ignore
        if (this.$cookies.get("apergiaToken") != null) {
            this.Log("Found token, try to validate " + this.$cookies.get("apergiaToken"));
            //@ts-ignore
            return RestClientApergia.adminLoginToken(null, null, this.$cookies.get("apergiaToken"))
                //@ts-ignore
                .then(value => {
                    let user = new UserStructure(value.data);
                    this.$store.commit('setUser', user);
                    //@ts-ignore
                    this.$cookies.set("apergiaToken", user.getToken());
                    return Promise.resolve(user);
                });
        } else {
            this.Log("No Token Found reject");
            return Promise.reject();
        }
    }

    CreateComponentOfType(type: string) {
        if (this.contentMap != null && this.contentMap.has(type)) {
            //@ts-ignore
            let structure: { content: string } = this.contentMap.get(type);
            this.Log("Creating component [" + type + "]");
            let component = this.CreateComponent(structure.content/*, structure.getData()*/);
            return component;
        } else {
            return this.CreateComponent("<div>Not Found</div>");
        }
    }

    CreateComponent(template: string) {
        return Vue.component(
            'dynamic-component',
            {
                components: {
                    VCard, VCardText, VCardActions, VCardTitle,
                    VSpacer, VIcon, VDivider,
                    VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, VListItemIcon, VListItemTitle, VListItemActionText, VListItemSubtitle, VListItemContent, VListItemGroup,
                    VTab, VTabItem, VTabs,
                    VToolbar, VToolbarTitle, VToolbarItems, VContainer, VCol, VRow, VChip, VAvatar
                },
                mixins: [ComponentBasic],
                template: template,
            });
    }

    ToEventTitle(event: EventStructure, isHideIfOld: boolean = false) {
        //@ts-ignore
        return ((isHideIfOld && new Date() > event.dateFrom) ? '' : UtilDate.toStringDiffInDays(new Date(), event.dateFrom) + ", ") + event.title.replace(",", ", ");
    }

    async LoadSettings() {
        this.Log(this.LN,"Loading Settings..");
        moment.locale("el");
        // Load Map
        try {
            let settingsPayload: PayloadStructure<SettingsStructure> = await RestClientApergia.getConfigAll(null, null);
            this.$store.commit('setSettings', settingsPayload);
        } catch (error) {
            this.$store.commit('setSettings', PayloadStructure.error(error));
        }
    }

    /* Summary (events, namedays)*/
    LoadSummary() {
        this.Log(this.LN,"Loading Summary");
        RestClientApergia.getSummary(
            value => {
                this.$store.commit('setSummary', PayloadStructure.info(new SummaryStructure(value.data), ""));
            },
            value => {
                this.Log("Failed loading summary:", value);
                this.$store.commit('setSummary', PayloadStructure.error("Failed loading summary"));
            },
        );
    }

    /*A single event using id (string or long)*/
    GetEventPromise(id: string): Promise<EventStructure> {
        return RestClientApergia.getEvent(null, null, id)
            .then((value) => Promise.resolve(new EventStructure(value.data)));
    }

    /*An EventWrapper using dateFrom, dateTo, text, if is_archive*/
    GetEventSearchPromise(dateFrom: Date | null, dateTo: Date | null, category: string | null, text: string | null, isArchive: boolean): Promise<EventsWrapperStructure> {
        return RestClientApergia.getEventSearch(null, null, dateFrom, dateTo, category, text, isArchive)
            .then((value) => {
                return Promise.resolve(new EventsWrapperStructure(value.data))
            });
    }

    GetEventStatsPromise(dateFrom: Date | null, dateTo: Date | null, categories: string[] | null): Promise<StatsStructure> {
        return RestClientApergia.getEventStats(null, null, dateFrom, dateTo, categories).then((value) => Promise.resolve(new StatsStructure(value.data)));
    }

    /*An array of events using dateFrom, dateTo and text*/
    GetEventsNameDaysPromise() {
        return RestClientApergia.getEventOfCategory(null, null, null, null, null)
            .then((value) => {
                return Promise.resolve(new EventsWrapperStructure(value.data))
            })
            .then((eventWrapper) => {
                if (eventWrapper != null && eventWrapper.listEvents != null) {
                    eventWrapper.listEvents = Array
                        .from(eventWrapper.listEvents, e => new EventStructure(e).setYearToDateFrom())
                        //@ts-ignore
                        .sort((a, b) => a.getDateFrom() > b.getDateFrom() ? 1 : -1);
                }
                return Promise.resolve(eventWrapper);
            });
    }

}

