








































































































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import Component, {mixins} from "vue-class-component";

@Component({})
export default class extends mixins(ComponentBasic) {
    LN: string = "[PageMarket]::";
    color: string = 'white';

    get hasCommonSideBar() {
        return true;
    }

    get getMarket() {
        return this.settings.getMarketStructure();
    }
}
