




















import {PayloadStructure, TypeMessage} from '@/model/basic/ModelBasic';
import {Prop, Watch} from "vue-property-decorator";
import {ComponentBasic} from '@/utilities/UtilitiesVue';
import Component, {mixins} from "vue-class-component";

@Component({})
export default class ComponentPayloadMessage extends mixins(ComponentBasic) {
    LN: string = "[PayloadMessageComponent]::";

    @Prop({default: PayloadStructure.loading("loading"), required: true})
    payload!: PayloadStructure<Object>;

    @Prop({default: 50, required: false})
    sizeSpinner!: number;

    @Prop({default: null, required: false})
    sizeFont!: string | null;

}
