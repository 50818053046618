/**
 * Contain Generic Structure (not related with the App business logic)
 * The structure may be used in other web-apps as well
 */
import {UtilitiesMath} from "@/utilities/basic/UtilitiesMath";
import {AxiosError} from "axios";
import {UtilitiesDate} from "@/utilities/basic/UtilitiesDate";

export interface SerializableJSON<T> {
  deserialize(jsonObject: Object): T;
}

/**
 * Utilities to make deserialization from json to typescript easily
 * And view the ready to use inline functions
 */
export class UtilitiesDeserialize {
  static deserializeMapString(jsonMap: any): Map<string, string> {
    return jsonMap != null ? new Map(Object.entries(jsonMap)) : new Map<string, string>();
    /*let map = new Map<string, string>();
    Object.keys(mapJson).forEach(key => map.set(key, mapJson[key]));
    return map;*/
  }

  static deserializerConverter<T>(jsonList: any, callbackfn: (value: any) => T): T[] {
    if (jsonList != null)
      return Array.from(jsonList, (e) => callbackfn(e));
    return [];
  }
}

export class InfoMessageStructure {
  msg: string | null = null;
  t: TypeMessage | null = null;

  constructor(jsonObject: object | null) {
    if (jsonObject != null)
      Object.assign(this, jsonObject);
  }

  getMessage(): string | null {
    return this.msg;
  }

  getType() {
    return this.t;
  }

  isEmptyMessage() {
    return this.msg == null || '' == this.msg;
  }

  static create(message: string, type?: TypeMessage) {
    let structure = new InfoMessageStructure(null);
    structure.msg = message;
    structure.t = type != null ? type : TypeMessage.Info;
    return structure;
  }

  static info(message: string) {
    return InfoMessageStructure.create(message, TypeMessage.Info);
  }

  static error(message: string) {
    return InfoMessageStructure.create(message, TypeMessage.Error);
  }

  static errorAxios(messagePrefix: string, error: AxiosError) {
    return InfoMessageStructure.create((messagePrefix || '') + (error != null && error.response != null && error.response.data != null && error.response.data.msg != null ? error.response.data.msg : ''), TypeMessage.Error);
  }

  static loading(message: string) {
    return InfoMessageStructure.create(message, TypeMessage.Loading);
  }

}

export class PayloadStructure<T> {
  message: InfoMessageStructure | null;
  payload: T | null;

  constructor(payload: T | null, message: InfoMessageStructure) {
    this.payload = payload;
    this.message = message;
  }

  hasData(): boolean {
    return this.payload != null;
  }

  getPayload(): T | null {
    return this.payload;
  }

  getType(): TypeMessage | null {
    return this.message != null ? this.message.getType() : null;
  }

  isOfType(type = TypeMessage.Info): Boolean {
    return this.message != null && this.message.getType() == type
  }

  getMessageString(): string | null {
    return this.message != null ? this.message.getMessage() : null;
  }

  getMessage(): InfoMessageStructure | null {
    return this.message;
  }

  toInfo(payload: T, msgString: string) {
    this.message = InfoMessageStructure.info(msgString);
    this.payload = payload;
  }

  toLoading(msgString: string) {
    this.message = InfoMessageStructure.loading(msgString);
    this.payload = null;
  }

  toError(msgString: string) {
    this.message = InfoMessageStructure.error(msgString);
    this.payload = null;
  }

  static info<T>(payload: T, msgString: string=""): PayloadStructure<T> {
    return new PayloadStructure<T>(payload, InfoMessageStructure.info(msgString))
  }

  static error<T>(msgString: string="error"): PayloadStructure<T> {
    return new PayloadStructure<T>(null, InfoMessageStructure.error(msgString))
  }

  static loading<T>(msgString: string="loading..."): PayloadStructure<T> {
    return new PayloadStructure<T>(null, InfoMessageStructure.loading(msgString))
  }
}

export interface VueTreeNode<T> {
  id: number | string
  name: string;
  children?: [VueTreeNode<T>];
  structure?: T;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export enum TypeMessage {
  Info = 0,
  Loading = 1,
  Warning = 10,
  Error = 11,
}

//Lighter Use of Position NOTE that google has lng not lon!!
export interface PosIF {
  lon: number;
  lat: number;
  zoom?: number;
}

export class PositionGIS implements PosIF {
  lon: number = 0;
  lat: number = 0;
  zoom?: number;
  title?: string;

  init(lon: number, lat: number, zoom?: number): PositionGIS {
    this.lon = lon;
    this.lat = lat;
    this.zoom = zoom;
    return this;
  }

  /* init(lon: number, lat: number, title?: string): PositionGIS {
     this.lon = lon;
     this.lat = lat;
     this.title = title;
     return this;
   }*/

  toStringPosition(isDMS = true): string {
    if (this.lat != null && this.lon != null)
      return isDMS ? UtilitiesMath.toDegreesMinutesSecondsString(this.lat) + "     " + UtilitiesMath.toDegreesMinutesSecondsString(this.lon) : this.lat.toFixed(5) + ', ' + this.lon.toFixed(5);
    return '';
  }

  toStringLatORLon(isDMS = true, isLat = true): string {
    if (this.lat != null && this.lon != null)
      return isDMS ? (isLat ? UtilitiesMath.toDegreesMinutesSecondsString(this.lat) : UtilitiesMath.toDegreesMinutesSecondsString(this.lon)) : (isLat ? this.lat.toFixed(5) : this.lon.toFixed(5));
    return '';
  }


  toURLLink(): string {
    return "https://www.google.com/maps/search/?api=1&query=" + this.lat + "," + this.lon;
  }

  toStringShort(): string {
    return PositionGIS.toStringShort(this);
  }

  static toStringShort(position: PosIF): string {
    return UtilitiesMath.roundTwo(position.lon) + "," + UtilitiesMath.roundTwo(position.lat);
  }

  isNotSet(): boolean {
    return this.lon == 0 && this.lat == 0;
  }
}


export class PositionStructure extends PositionGIS {
  thd?: number | null = null;
  sog?: number | null = null;
  dt?: Date | null = null;

  constructor(jsonObject: object) {
    super();
    this.init(this.lon, this.lat, 0);
    Object.assign(this, jsonObject);
    if (this.dt != null)
      this.dt = new Date(this.dt);
  }

  /*toDate(isFromNow: boolean = false): string {
    if (this.dt) {
      if (isFromNow)
        return UtilitiesDate.format(this.dt);
      else
        return UtilitiesDate.formatFromNow(this.dt)
    }
    return "";
  }*/

  equalsLonLat(position: PositionStructure): boolean {
    return this.lat == position.lat && this.lon == position.lon;
  }
}

export class DatePeriodStructure {
  dateFrom: Date | null = null;
  dateTo: Date | null = null;
  isRelative?: boolean = false;

  init(dateFrom: Date | null, dateTo: Date | null): DatePeriodStructure {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    return this;
  }

  getDiffDays(postfix:string = ' days(s)'): string {
    if (this.dateFrom != null && this.dateTo != null)
      return UtilitiesDate.getDaysDiff(this.dateFrom, this.dateTo, postfix);
    return "";
  }

  isDateInPeriod(date: Date = new Date()): boolean {
    if (this.dateFrom && this.dateTo)
      return date.getTime() > this.dateFrom.getTime() && date.getTime() < this.dateTo.getTime();
    return false;
  }

  isValid(): boolean {
    return this.dateFrom != null && this.dateTo != null && this.dateTo >= this.dateFrom;
  }

  isAfter(date = new Date()) {
    return this.dateFrom != null && this.dateFrom.getTime() > date.getTime()
  }

}






