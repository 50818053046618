




























































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {Component, Mixins, Prop} from "vue-property-decorator";
import {EventStructure} from "@/model/ModelApp";
import {Language} from "@/utilities/UtilitiesApp";

@Component({})
export default class ListTrends extends Mixins(ComponentBasic) {
    LN: string = "[ListTrends]::";
    listFiltered: EventStructure[] = [];
    payload: PayloadStructure<EventStructure[]> = PayloadStructure.loading(Language.pleaseWait);
    fontSize: string = '14px';
    contentId: number = 1;
    listTitle: string[] = ['Τελευταία Νέα', 'Δημοφιλή Video (Ελλάδα)', 'Δημοφιλή Video (Κόσμος)'];
    listComponent: any | null[] = [null, null, null];

    @Prop({default: new Date().getFullYear()})
    year!: number;

    @Prop({default: true})
    selectable!: boolean;

    @Prop({default: null})
    day!: string | null;

    @Prop({default: '28px'})
    imgSize!: string;

    @Prop({default: 450})
    heightDiv!: number;

    @Prop({default: true})
    showToolbar!: boolean;

    @Prop({default: false})
    showDate!: boolean;

    @Prop({default: 1})
    contentIdStart!: number;

    @Prop({default: 'white'})
    color!: string;

    @Prop({default: ''})
    title!: string;

    created() {
        // this.init();
      this.Log("Start News, default content id:"+this.contentIdStart);
      this.contentId = this.contentIdStart;
    }

    refresh() {
        let element = null;
        if (this.contentId == 0)
            element = this.$refs.componentListNews;
        if (this.contentId == 1)
            element = this.$refs.componentListYoutubeGr;
        if (this.contentId == 2)
            element = this.$refs.componentListYoutubeGlobal;

        if (element != null)
        //@ts-ignore
            element.loadData()
    }

    goTo(url: string) {
        this.$router.push({path: url});
    }
}

